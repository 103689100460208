import React, { useState, Fragment } from 'react';
import Inicio from '../../Components/DashboardColaborador/Inicio';
import HeaderColaborador from '../../Components/Layout/HeaderColaborador';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const DashboardColaborador = () => {
    
    const { isAuthenticated, loading, type } = useSelector(state => state.auth)
    
    // Redireccionamos si está autenticado
    if (isAuthenticated && type === 1) return <Redirect push to="/dashboard_medico" />;
    if (isAuthenticated && type === 2) return <Redirect push to="/dashboard_paciente" />;

    return (
        <Fragment>
            <HeaderColaborador />
            <Inicio />
        </Fragment>
    )
}

export default DashboardColaborador;