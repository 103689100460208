import { LOAD_MEDICOS, AGREGAR_MEDICO } from '../actions/types'

const initialState = {};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOAD_MEDICOS:
            return payload;
        case AGREGAR_MEDICO:
            return [
                ...state, payload
            ];
        default:
            return state;
    }

}