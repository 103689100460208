import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { unsuscribeColaborador } from '../../Redux/actions/auth';

const styles = {
    rowStyle: {
        height: '80vh',
    },
    bgImg: {
        height: '40vw',
        opacity: 0.05,
        position: 'absolute',
        left: '-220px',
        top: '0px',
    },
    btnIniciar: {
        background: '#42B4D9',
        borderColor: '#42B4D9'
    }    
}

const DesuscripcionColaborador = ({ }) => {

    const dispatch = useDispatch();

    const { id } = useParams();
    
    const [desuscripcionData, setDesuscripcionData] = useState({
        id: null, 
        enviado: false
    });

    /* Solicitar */
    const submitSolicitar = async () => {
        setDesuscripcionData({
            ...desuscripcionData, 
            enviado: true
        });

        dispatch(unsuscribeColaborador(desuscripcionData.id));
    }

    useEffect(() => {
        if(id){
            setDesuscripcionData({
                ...desuscripcionData, 
                id
            });
        }
    }, [id]);

    return (
        <div className="row d-flex align-items-center" style={styles.rowStyle}>
            <img src="/assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-6 offset-md-3">
                <div className="card bg-light ">
                    <div className="card-body">
                        <div className="text-center mb-5">
                            <img src="/assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" height="45" />
                            <h4 className="card-title mb-4">Desuscripción de correos electrónicos</h4>
                            <p className="text-muted">¿Realmente deseas dejar de recibir correos electrónicos por parte Medpacom? Este proceso no podrá revertirse una vez que presiones en "Aceptar".</p>
                        </div>
                        
                        <div className="form-group text-center">
                            <button type="button" className="btn btn-primary" style={styles.btnIniciar} onClick={() => submitSolicitar()} disabled={desuscripcionData.enviado}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default DesuscripcionColaborador;