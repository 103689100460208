import React from 'react';
import { logout } from '../../Redux/actions/auth'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';

const styles = {
    navbar: {
        background: '#42B4D9'
    }
}

const HeaderColaborador = () => {

    const dispatch = useDispatch();

    const location = useLocation();

    return (
        <nav className="navbar navbar-expand-lg navbar-dark" style={styles.navbar}>
            <a className="navbar-brand font-weight-bold" href="javascript:void(0)">
                <img src="/assets/images/logo_blanco.png" className="d-inline-block" alt="logo" height="35" />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className={location.pathname === '/dashboard_colaborador' ? 'nav-item active' : 'nav-item'}>
                        <a href="/dashboard_colaborador" className="nav-link">Inicio</a>
                    </li>
                    <li className={location.pathname === '/perfil_colaborador' ? 'nav-item active' : 'nav-item'}>
                        <a href="/perfil_colaborador" className="nav-link">Perfil</a>
                    </li>
                    <li className={location.pathname === '/medicos_colaborador' ? 'nav-item active' : 'nav-item'}>
                        <a href="/medicos_colaborador" className="nav-link">Medicos</a>
                    </li>
                </ul>
                <div className="form-inline my-2 my-lg-0">
                    <a href="javascript:void(0)" className="nav-link text-white" onClick={() => {
                        dispatch(logout())
                    }}>Cerrar sesión</a>
                </div>
            </div>
        </nav>
    )
}

export default HeaderColaborador;