import React from 'react'
import { Container, Row } from 'react-bootstrap'

const AboutPaciente = () => {
    return (
        <Container>
            
            <h5 className="pt-5">QUIENES SOMOS</h5>

            <blockquote className="heading post contenido_nosotros">
                Medpacom está formado por un equipo de profesionales comprometidos con los doctores, doctoras y sus pacientes, en todos los países donde tenemos presencia.
            </blockquote>

            <h5 className="pt-5">EQUIPO:</h5>

            <ul className="contenedor_lista_miembros">
            	<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Oscar Luis De la Garza González / Fundador / Director General</li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Oscar De la Garza Lozano / Fundador / Presidente del consejo médico de MEDPACOM y dirección de relaciones públicas. </li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Hernán Patricio González Segovia / Fundador / Director de Marketing</li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Miguel de Jesús Muñoz Perea / Fundador /  Director Financiero</li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Paola Denysse Reichardt Vera / Director de Operaciones e Innovación </li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Javier Edgardo Núñez González / Director Jurídico.</li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> César Alfonso Leal González -  Solgeek  / Dirección de desarrollo tecnológico.</li>
			</ul>

            <h5 className="pt-5">PRIMER CONSEJO MÉDICO DE MEDPACOM</h5>
            
            <div id="showcase-projects" className="row mt-5">
                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/11.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Presidente</h6>
                            <p className="heading margin-null">Dr. Oscar De la Garza Lozano</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/8.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Vicepresidente</h6>
                            <p className="heading margin-null">Dr. Juan De Dios Maldonado Alvarado</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/7.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Secretario</h6>
                            <p className="heading margin-null">Dr. Jonatán De la Garza González</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/3.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Cuauhtémoc Celis González</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/13.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Roberto Hernández Niño</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/4.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Felipe Pérez Rada</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/18.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejera</h6>
                            <p className="heading margin-null">Dra. Dolores Vázquez Alemán</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/10.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Alberto Chapa Lobo</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/9.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Marco Tulio Flores Gutiérrez</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/1.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Arturo Ramírez Leal&nbsp;</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/2.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Carlos Gutiérrez Flores</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/19.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Marcos Ibarra Flores</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/17.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejera</h6>
                            <p className="heading margin-null">Dra. Cecilia Britton Robles</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/6.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Jaime Torres Gómez</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/14.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Víctor Batiza Reséndiz</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/15.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Víctor López Valeiro&nbsp;</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/12.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Ricardo de Hoyos Parra</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/16.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Wilfrido Nevárez Álvarez</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/0.png" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Carlos Díaz Olachea&nbsp;</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/20.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Rómulo Ramírez Gutiérrez</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/21.jpeg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Gilberto Montiel Amoroso</p>
                        </div>
                    </div>
                </div>
            </div>

            <h5 className="pt-5">REPRESENTANTES DE MÉXICO</h5>
            
            <div id="showcase-projects" className="row mt-5">
                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/representantes/1.jpeg" alt="" />
                        <div className="content">
                            <h6 className="heading black">MÉXICO | CDMX</h6>
                            <p className="heading margin-null">Dr. Marcos A. Rodríguez Perales</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/representantes/3.jpeg" alt="" />
                        <div className="content">
                            <h6 className="heading black">MÉXICO | REYNOSA</h6>
                            <p className="heading margin-null">Dr. Marco Antonio Bracho Ugarte</p>
                        </div>
                    </div>
                </div>
            </div>

            <h5 className="pt-5">REPRESENTANTES DE MEDPACOM EN EL MUNDO</h5>
            
            <div id="showcase-projects" className="row mt-5 pb-5">
                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/representantes/2.jpeg" alt="" />
                        <div className="content">
                            <h6 className="heading black">ESPAÑA | PRESIDENTE DEL CONSEJO MEDICO DE MEDPACOM UE</h6>
                            <p className="heading margin-null">Dr. José Luis Neyro Bilbao</p>
                        </div>
                    </div>
                </div>
            </div>

        </Container>
    )
}

export default AboutPaciente;