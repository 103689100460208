import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { recoverMedico, deleteMedico } from '../../Redux/actions/auth';
import { setAlert } from '../../Redux/actions/alert';

const styles = {
    rowStyle: {
        height: '80vh',
    },
    bgImg: {
        height: '40vw',
        opacity: 0.05,
        position: 'absolute',
        left: '-220px',
        top: '0px',
    },
    btnIniciar: {
        background: '#42B4D9',
        borderColor: '#42B4D9'
    }    
}

const EliminacionMedico = () => {

    const dispatch = useDispatch();

    const { codigo } = useSelector(state => state.auth);
    
    const [solicitarData, setSolicitarData] = useState({
        email: null,
        codigo_solicitar_1: '',
        codigo_solicitar_2: '',
        codigo_solicitar_3: '',
        codigo_solicitar_4: '',
        solicitando: true,
        confirmando: false,
        eliminando: false
    });

    let ref_numero_2 = createRef();
    let ref_numero_3 = createRef();
    let ref_numero_4 = createRef();

    /* Solicitar */
    const onChangeEmailSolicitar = (e) => {
        setSolicitarData({
            ...solicitarData, [e.target.name]: e.target.value
        })
    }

    const handleKeyPressSolicitar = (e) => {
        if(e.keyCode === 13){
            submitSolicitar();
        }
    }

    const submitSolicitar = async () => {
        dispatch(recoverMedico(solicitarData.email));
    }

    /* Confirmar */
    const onChangeCodigoSolicitar = (e) => {
        setSolicitarData({
            ...solicitarData, [e.target.name]: e.target.value
        });
    }

    const handleKeyPressConfirmar = (e) => {
        if(e.keyCode === 13){
            submitConfirmar();
        }
    }

    const submitConfirmar = async () => {

        let {codigo_solicitar_1, codigo_solicitar_2, codigo_solicitar_3, codigo_solicitar_4} = solicitarData;

        let codigo_usuario = codigo_solicitar_1 + '' + codigo_solicitar_2 + '' + codigo_solicitar_3 + '' + codigo_solicitar_4;

        if (codigo == codigo_usuario) {
            dispatch(setAlert('Código correcto', 'success'));
            
            setSolicitarData({
                ...solicitarData,
                codigo_solicitar_1: '',
                codigo_solicitar_2: '',
                codigo_solicitar_3: '',
                codigo_solicitar_4: '',
                solicitando: false,
                confirmando: false,
                eliminando: true
            });
        }else{
            dispatch(setAlert('El código no coincide', 'danger'));
        }
    }

    /* Eliminar */
    const submitEliminar = async () => {
        dispatch(deleteMedico({ email: solicitarData.email }));

        setSolicitarData({
            ...solicitarData,
            email: null,
            codigo_solicitar_1: '',
            codigo_solicitar_2: '',
            codigo_solicitar_3: '',
            codigo_solicitar_4: '',
            solicitando: true,
            confirmando: false,
            eliminando: false
        });

    }

    /* Funciones generales */
    const handleOpenDelete = () => {
        setSolicitarData({
            ...solicitarData,
            email: null,
            codigo_solicitar_1: '',
            codigo_solicitar_2: '',
            codigo_solicitar_3: '',
            codigo_solicitar_4: '',
            solicitando: true,
            confirmando: false,
            eliminando: false
        });
    }

    useEffect(() => {
        if(codigo){
            setSolicitarData({
                ...solicitarData, 
                codigo_solicitar_1: '',
                codigo_solicitar_2: '',
                codigo_solicitar_3: '',
                codigo_solicitar_4: '',
                solicitando: false,
                confirmando: true,
                eliminando: false
            });
        }
    }, [codigo]);

    useEffect(() => {
        if(solicitarData.codigo_solicitar_1 != ''){
            ref_numero_2.current.focus();
        }
    }, [solicitarData.codigo_solicitar_1]);

    useEffect(() => {
        if(solicitarData.codigo_solicitar_2 != ''){
            ref_numero_3.current.focus();
        }
    }, [solicitarData.codigo_solicitar_2]);
    
    useEffect(() => {
        if(solicitarData.codigo_solicitar_3 != ''){
            ref_numero_4.current.focus();
        }
    }, [solicitarData.codigo_solicitar_3]);

    return (
        <div className="row d-flex align-items-center" style={styles.rowStyle}>
            <img src="/assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-6 offset-md-3">
                <div className="card bg-light"> 
                    <div className="card-body">
                        {
                            solicitarData.solicitando ?
                                <div className="row">
                                    <div className="col-md-12 text-center mb-3">
                                        <img src="/assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" height="45" />
                                        <h4 className="card-title">Eliminación de cuenta</h4>
                                    </div>

                                    <div className="col-md-12 p-3">
                                        <p className="text-muted">Ingresa tu cuenta de correo para eliminar tu cuenta de usuario en la App Medpacom Profesional. Te enviaremos un código de 4 dígitos a tu correo para este proceso.</p>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" placeholder="Correo" id="email_recuperar" name="email" value={solicitarData.email || ''} onChange={e => onChangeEmailSolicitar(e)} onKeyDown={handleKeyPressSolicitar}></input>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group text-right pt-3">
                                            <button type="button" className="btn btn-primary" onClick={() => submitSolicitar()}>Envíar código</button>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        }

                        {
                            solicitarData.confirmando ?
                                <div className="row">
                                    <div className="col-md-12 text-center mb-3">
                                        <img src="/assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" height="45" />
                                        <h4 className="card-title">Código de eliminación</h4>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group text-center">
                                            <input
                                                type="text"
                                                className="codigo"
                                                name="codigo_solicitar_1"
                                                min="0"
                                                max="9"
                                                maxLength="1"
                                                value={ solicitarData.codigo_solicitar_1 || ''}
                                                onChange={e => onChangeCodigoSolicitar(e)}
                                                onKeyDown={handleKeyPressConfirmar}></input>
                                            <input
                                                type="text"
                                                className="codigo"
                                                name="codigo_solicitar_2"
                                                min="0"
                                                max="9"
                                                maxLength="1"
                                                ref={ref_numero_2}
                                                value={ solicitarData.codigo_solicitar_2 || ''}
                                                onChange={e => onChangeCodigoSolicitar(e)}
                                                onKeyDown={handleKeyPressConfirmar}></input>
                                            <input
                                                type="text"
                                                className="codigo"
                                                name="codigo_solicitar_3"
                                                min="0"
                                                max="9"
                                                maxLength="1"
                                                ref={ref_numero_3}
                                                value={ solicitarData.codigo_solicitar_3 || ''}
                                                onChange={e => onChangeCodigoSolicitar(e)}
                                                onKeyDown={handleKeyPressConfirmar}></input>
                                            <input
                                                type="text"
                                                className="codigo"
                                                name="codigo_solicitar_4"
                                                min="0"
                                                max="9"
                                                maxLength="1"
                                                ref={ref_numero_4}
                                                value={ solicitarData.codigo_solicitar_4 || ''}
                                                onChange={e => onChangeCodigoSolicitar(e)}
                                                onKeyDown={handleKeyPressConfirmar}></input>
                                        </div>
                                    </div>

                                    <div className="col-md-12 form-group text-left">
                                        <button type="button" className="btn btn-link btnRecuperar" onClick={() => handleOpenDelete()}>Volver al inicio</button>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group text-right pt-3">
                                            <button type="button" className="btn btn-primary" onClick={() => submitConfirmar()}>Validar código</button>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        }

                        {
                            solicitarData.eliminando ?
                                <div className="row">
                                    <div className="col-md-12 text-center mb-3">
                                        <img src="/assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" height="45" />
                                        <h4 className="card-title">Eliminación de cuenta</h4>
                                    </div>

                                    <div className="col-md-12 p-3">
                                        <p className="text-muted">¿Estás completamente seguro de eliminar tu cuenta de la App Medpacom Profesional? Una vez que concluya el proceso no podrás recuperar tu cuenta ni ninguno de los datos en ella.</p>
                                    </div>

                                    <div className="col-md-12 form-group text-left">
                                        <button type="button" className="btn btn-link btnRecuperar" onClick={() => handleOpenDelete()}>Volver al inicio</button>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group text-right pt-3">
                                            <button type="button" className="btn btn-primary" onClick={() => submitEliminar()}>Eliminar cuenta</button>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EliminacionMedico;