import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


const Inicio = () => {

    const user = useSelector(state => state.auth.user);

    const styles = {
        imgContainer: {
            width: 200, height: 200,
            borderRadius: '100px',
            backgroundImage: user ? `url(${user.url_perfil})` : '#AEAEAE',
            backgroundSize: 'cover',
        },
        colorPrimary: {
            color: '#42B4D9'
        },
        subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
        info: { margin: 0, marginBottom: 10 },
    }

    return (
        user ? <div className="py-5 container-fluid">
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col-md-12 my-4" style={styles.imgContainer}></div>
                            <h3>{user.nombre} {user.apellido}</h3>
                            <h6 className="text-muted">{user.especialidad.nombre}</h6>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md">
                                    <h3>{user.numero_consultas}</h3>
                                    <p>consultas realizadas</p>
                                </div>
                                <div className="col-md">
                                    <h3>{user.numero_pacientes}</h3>
                                    <p>pacientes</p>
                                </div>
                                <div className="col-md">
                                    <h3>{user.numero_medicos}</h3>
                                    <p>medicos en la red</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <h4 style={styles.colorPrimary}>Información Personal</h4>
                                </div>
                                <div className="col-md-12">
                                    <p style={styles.subtitulo}>Nombre</p>
                                    <p style={styles.info}>{user.nombre} {user.apellido}</p>
                                    <p style={styles.subtitulo}>Fecha de nacimiento</p>
                                    <p style={styles.info}>{new Date(user.nacimiento).toLocaleDateString('es-MX')}</p>
                                    <p style={styles.subtitulo}>Correo</p>
                                    <p style={styles.info}>{user.email}</p>
                                    <p style={styles.subtitulo}>Celular</p>
                                    <p style={styles.info}>{user.celular}</p>
                                    <p style={styles.subtitulo}>Especialidad</p>
                                    <p style={styles.info}>{user.especialidad ? user.especialidad.nombre : 'Médico general'}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <h4 style={styles.colorPrimary}>Información Profesional</h4>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <p className="my-0 font-weight-bold col-md">Especialidad</p>
                                        <p className="my-0 font-weight-bold col-md">Cédula</p>
                                    </div>
                                    <div className="row">
                                        <span className="my-0 col-md">{user.especialidad.nombre}</span>
                                        <span className="my-0 col-md">{user.cedula}</span>
                                    </div>
                                    {
                                        user.especialidades.length > 0 &&
                                        <div className="row mt-4">
                                            <p className="my-0 font-weight-bold col-md">Subespecialidad(es)</p>
                                            <p className="my-0 font-weight-bold col-md">Cédula</p>
                                        </div>
                                    }
                                    {
                                        user.especialidades.length > 0 && user.especialidades.map(especialidad =>
                                            <div key={especialidad._id} className="row">
                                                <span className="my-0 col-md">{especialidad.especialidad.nombre}</span>
                                                <span className="my-0 col-md">{especialidad.cedula_especialidad}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        user.estudios.length > 0 &&
                                        <div className="row mt-4">
                                            <p className="my-0 font-weight-bold col-md">Estudio</p>
                                            <p className="my-0 font-weight-bold col-md">Fecha</p>
                                        </div>
                                    }
                                    {
                                        user.estudios.length > 0 && user.estudios.map(estudio =>
                                            <div key={estudio._id} className="row">
                                                <span className="my-0 col-md">{estudio.titulo}</span>
                                                <span className="my-0 col-md">{estudio.inicio} - {estudio.fin}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        user.experiencia.length > 0 &&
                                        <div className="row mt-4">
                                            <p className="my-0 font-weight-bold col-md">Cargo</p>
                                            <p className="my-0 font-weight-bold col-md">Fecha</p>
                                        </div>
                                    }
                                    {
                                        user.experiencia.length > 0 && user.experiencia.map(exp =>
                                            <div key={exp._id} className="row">
                                                <span className="my-0 col-md">{exp.cargo}</span>
                                                <span className="my-0 col-md">{exp.inicio} - {exp.fin}</span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : <div></div>
    )
}

export default Inicio;