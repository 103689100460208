import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({
    component: Component,
    ...rest
}) => {
    const { isAuthenticated, loading } = useSelector(state => state.auth);
    if (!isAuthenticated && !loading) return <Redirect to='/' />
    return (
        <Route
            {...rest}
            render={props => <Component {...props} />}
        />
    )
}

export default PrivateRoute;
