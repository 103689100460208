import axios from 'axios';
import { LOAD_PERFIL, EDITAR_PERFIL, USER_LOADED } from './types';
import { setAlert } from './alert';

// LOAD USER
export const loadPerfil = () => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/web_colaborador_perfil`);
        dispatch({
            type: LOAD_PERFIL,
            payload: res.data
        })
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// Subir la factura
export const editarPerfil = (perfil) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(perfil);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_colaborador_perfil/editar`, body, config);
        console.log(res);
        dispatch({
            type: EDITAR_PERFIL,
            payload: res.data
        });
        dispatch({
            type: USER_LOADED,
            payload: {
                user: res.data.colaborador,
                type: 3
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}
