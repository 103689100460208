import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../Redux/actions/auth'

const HeaderSSMedico = () => {

    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout())
    }
    return (
        <header id="header" className="fixed transparent">
            <nav className="navbar navbar-default white">
                <div id="logo">
                    <a className="navbar-brand" href="/home">
                        <img src="/assets/images/logo_azul.png" className="normal" alt="logo" />
                        <img src="/assets/images/logo_azul.png" className="retina" alt="logo" />
                        <img src="/assets/images/logo_blanco.png" className="normal white-logo" alt="logo" />
                        <img src="/assets/images/logo_blanco.png" className="retina white-logo" alt="logo" />
                    </a>
                </div>

                <div id="menu-classic">
                    <div className="menu-holder">
                        <ul>
                            <li>
                                <a href="/medico" className="active-item">INICIO</a>
                            </li>
                            <li>
                                <a href="/about" className="active-item">NOSOTROS</a>
                            </li>
                            <li>
                                <a href="/tutorial_medico" className="active-item">TUTORIALES</a>
                            </li>
                            <li>
                                <a href="#bottom_footer" id="contacto" className="active-item">CONTACTO</a>
                            </li>
                            <li className="submenu">
                                {
                                    auth.isAuthenticated ?
                                        <button onClick={handleLogout} className="active-item">CERRAR SESION</button>
                                        :
                                        <a href="/login_medico">INICIAR SESION</a>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="menu-responsive-classic">
                    <div className="menu-button">
                        <span className="bar bar-1"></span>
                        <span className="bar bar-2"></span>
                        <span className="bar bar-3"></span>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default HeaderSSMedico;