import React, { Fragment, useEffect, useState, createRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { loadMedicos, agregarMedico, crearMedico } from '../../Redux/actions/medicos';
import { useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

registerLocale('es', es);

const styles = {
    buttonIcon: {
        fontSize: 30,
    }
}

const Medicos = () => {

    const medicos = useSelector(state => state.medicos);

    useEffect(() => {
        load();
    }, []);

    const dispatch = useDispatch();
    const load = () => {
        dispatch(loadMedicos());
    }

    const [agregarModal, setAgregarModal] = useState(false);
    const [crearModal, setCrearModal] = useState(false);

    const [agregarMedicoData, setAgregarMedicoData ] = useState({
        email_agregar: ''
    });

    const [crearMedicoData, setCrearMedicoData ] = useState({
        nombre: '',
        apellido: '',
        nacimiento: null,
        genero: null,
        cedula: '',
        email: ''
    });

    const { nombre, apellido, nacimiento, genero, cedula, email } = crearMedicoData;

    const onChangeAgregar = (e) => {
        setAgregarMedicoData({
            ...agregarMedicoData, [e.target.name]: e.target.value
        })
    }

    const onChangeCrear = (e) => {
        setCrearMedicoData({
            ...crearMedicoData, [e.target.name]: e.target.value
        })
    }

    const handleAgregar = () => {
        setAgregarModal(true);
    }

    const handleCrear = () => {
        setCrearModal(true);
    }

    const handleAgregado = () => {
        dispatch(agregarMedico(agregarMedicoData));
        setAgregarModal(false);
        setAgregarMedicoData({
            email_agregar: ''
        });
    }

    const handleCreado = () => {
        dispatch(crearMedico(crearMedicoData));
        setCrearModal(false);
        setCrearMedicoData({
            nombre: '',
            apellido: '',
            nacimiento: null,
            genero: null,
            cedula: '',
            email: ''
        });
    }

    const tableRowMedico = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre} {item.apellido}</td>
                            <td>{item.email}</td>
                            <td>{item.ganancia_total}</td>
                            <td>{new Date(item.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    return (
        <Fragment>
            {/* Modal de agregado */}
            <Modal show={agregarModal} onHide={() => {
                setAgregarModal(false);
                setAgregarMedicoData({
                    email_agregar: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar un médico a tu red</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 form-group">
                            <label className="font-weight-bold">Email del médico:</label>
                            <input type="text" className="form-control" placeholder="Email" name="email_agregar" onChange={ e => onChangeAgregar(e) } />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAgregado}>
                        Agregar
                    </Button>
                </Modal.Footer>
            </Modal>

        {/* Modal de creacion */}
            <Modal size="lg" show={crearModal} onHide={() => {
                setCrearModal(false);
                setCrearMedicoData({
                    nombre: '',
                    apellido: '',
                    nacimiento: null,
                    genero: null,
                    cedula: '',
                    email: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Crea la cuenta de un médico y agrégalo a tu red</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label className="font-weight-bold">Nombre:</label>
                            <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={ e => onChangeCrear(e) } />
                        </div>

                        <div className="col-6 form-group">
                            <label className="font-weight-bold">Apellido:</label>
                            <input type="text" className="form-control" placeholder="Apellido" name="apellido" onChange={ e => onChangeCrear(e) } />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label className="font-weight-bold">Fecha de nacimiento:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ nacimiento ? new Date(nacimiento) : '' } onChange={ date => setCrearMedicoData({ ...crearMedicoData, nacimiento: date })}/>
                            </div>  
                        </div>

                        <div className="col-6 form-group">
                            <label className="font-weight-bold">Sexo:</label>
                            <select id="select_productos" name="genero" className="form-control" onChange={ e => onChangeCrear(e) }>
                                <option value={null}>Elige una opción</option>
                                <option value={'f'}>Mujer</option>
                                <option value={'h'}>Hombre</option>                                
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label className="font-weight-bold">Cédula profesional:</label>
                            <input type="text" className="form-control" placeholder="Cédula profesional" name="cedula" onChange={ e => onChangeCrear(e) } />
                        </div>

                        <div className="col-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <input type="text" className="form-control" placeholder="Email" name="email" onChange={ e => onChangeCrear(e) } />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCreado}>
                        Agregar
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="row mt-5">
                <div className="col-md-4 offset-md-2">
                    <button type="button" className="btn btn-light btn-block" onClick={handleAgregar}>
                        <div className="col-md-12 py-2">
                            <i className="fa fa-stethoscope" style={styles.buttonIcon} />
                        </div>
                        Agregar médico ya existente
                    </button>
                </div>
                <div className="col-md-4">
                    <button type="button" className="btn btn-light btn-block" onClick={handleCrear}>
                        <div className="col-md-12 py-2">
                            <i className="fa fa-user-md" style={styles.buttonIcon} />
                        </div>
                        Crear y agregar nuevo médico
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-12">
                            <h3>Médicos en tu red</h3>
                            <p className="text-muted">{medicos?.length} médico{medicos?.length == 1 ? '' : 's'} agregado{medicos?.length == 1 ? '' : 's'}</p>
                        </div>
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Email</th>
                                <th scope="col">Honorarios generados</th>
                                <th scope="col">Fecha de inicio</th>
                            </tr>
                        </thead>
                        {
                            tableRowMedico(medicos !== undefined ? (medicos.length > 0 ? medicos : []) : [])
                        }
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

export default Medicos;