import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const HomeMedico = () => {

    const { isAuthenticated, loading, type } = useSelector(state => state.auth)
    
    if (isAuthenticated && type === 1) return <Redirect push to="/dashboard_medico" />;
    if (isAuthenticated && type === 2) return <Redirect push to="/dashboard_paciente" />;
    
    return (
    	<Fragment>
			<div id="slider-video" className="fullpage-wrap">
			    <div className="local-video" style={{ backgroundImage: 'url(assets/images/portada.jpg)' }}>
			        <div className="text">
			            <h1 className="big title white center margin-bottom" style={{ fontFamily: 'Nevis' }}>MEDPACOM</h1>
			            <h2 id="susbtitulo_principal" className="small white uppercase" style={{ fontFamily: 'Nevis', lineHeight: '30px', fontSize: '18px' }}>La primera aplicacion que te permite profesionalizar el servicio de la comunicacion a distancia con tus pacientes habituales y futuros, bienvenido a la evolucion de la comunicacion con los profesionales de la salud</h2>
			        </div>
			        <div className="gradient"></div>
			    </div>
			</div>

			<div id="home-wrap" className="content-section">
				<div className="row padding-md margin-leftright-null dark-background">
                    <div className="col-md-12 text-center">
                        <h4 className="big margin-bottom-small white">Somos la primera plataforma digital de salud y comunicación en el mundo con un Consejo Médico formado por presidentes, ex presidentes, y vicepresidentes de consejos nacionales e internacionales, directores y ex directores de hospitales privados y públicos, líderes en investigaciones médicas y extraordinarios seres humanos.</h4>
                    </div>
                </div>

                <div className="container-fluid">
			        <div className="row grey-background conocenos py-4">
			            <div className="col-md-12 col-sm-12 verticalalign">
		                    <h3 className="margin-bottom-small titulo text-center">ESTO ES MEDPACOM</h3>
			            </div>
			        	<div className="offset-md-3 col-md-6 col-12 text-center">
			        		<div className="video-responsive">
				        		<iframe src="https://www.youtube.com/embed/yNeuUEbL5mU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				        	</div>
			        	</div>
		            </div>
			    </div>

                <div className="container-fluid">
			        <div className="row conocenos">
					    <div className="col-sm-12 col-md-6 padding-leftright-null">
					        <div className="text text-center padding-md-bottom-null">
					            <i className="icon fa fa-cogs service color"></i>
					            <h6 className="medium dark">¿CÓMO FUNCIONA?</h6>
					            <p className="margin-md-bottom-null" style={{ textAlign: 'justify' }}>A través de una aplicación móvil, el profesional de la salud tiene acceso a un medio profesional de comunicación que podrá compartir con sus pacientes de forma privada para continuar en contacto, antes, durante y después de la visita al consultorio. En Medpacom tendrá múltiples opciones para administrar su tiempo y sus ingresos, tendrá una perspectiva de cada uno de sus pacientes y contará con el servicio de intranet para estar en comunicación con sus colegas.</p>
					        </div>
					    </div>
					    <div className="col-sm-12 col-md-6 padding-leftright-null">
					        <div className="text text-center padding-md-bottom-null">
					            <i className="icon fa fa-money service color"></i>
					            <h6 className="medium dark">¿CUÁNTO CUESTA MEDPACOM?</h6>
					            <p className="margin-md-bottom-null" style={{ textAlign: 'justify' }}>Nuestra plataforma es totalmente gratuita, no cuenta con una renta mensual, anual o similar, al contrario, los profesionales de la salud son los dueños del 90% de los ingresos que la compañía reciba a través de los ingresos que el profesional de la salud genere en la plataforma, solo el 10% será el monto destinado para nuestro gasto operativo, ingresos y desarrollo. Siempre será nuestra filosofía el ser una pequeña parte de su increíble trabajo.</p>
					        </div>
					    </div>
					</div>
				</div>

			    <div className="container-fluid">
			        <div className="row grey-background conocenos">
			        	<div className="col-md-6 col-sm-12 hiddensm" style={{ backgroundImage: 'url(assets/images/celulares.png)', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '450px' }}></div>
			            <div className="col-md-6 col-sm-12 verticalalign px-5">
		                    <h3 className="margin-bottom-small titulo">DISPONIBLE EN TIENDAS</h3>
		                    <p className="text-justify" style={{ paddingTop: '15px' }}>Estamos disponibles en las principales tiendas de aplicaciones, tanto en App Store como Play Store. Puedes descargarlas dando click en los siguientes botones:</p>
		                    <div className="row mt-5">
		                		<div className="col-md-6 col-sm-12 text-center">
		                			<a href="https://apps.apple.com/mx/app/id1521380093" target="_blank">
		                				<img src="assets/images/appstore.png" alt="" style={{ height: '50px' }} />
	                				</a>
	                			</div>
	                			<div className="col-md-6 col-sm-12 text-center">
	                				<a href="https://play.google.com/store/apps/details?id=com.solgeek.medpacomprofesional" target="_blank">
		                				<img src="assets/images/playstore.png" alt="" style={{ height: '50px' }} />
	                				</a>
	                			</div>
                			</div>
			            </div>
		            </div>
			    </div>

			    <div className="container-fluid">
			        <div className="row conocenos">
			            <div className="col-md-6 col-sm-12 verticalalign px-5">
		                    <h3 className="margin-bottom-small titulo">SEGUIMIENTO Y CALIDAD</h3>
		                    <p className="text-justify" style={{ paddingTop: '15px' }}>Contamos con herramientas que nos permiten identificar las necesidades de nuestros usuarios, y mejorar dia con dia la experiencia tanto del profesional como de cada paciente.</p>
			            </div>
			        	<div className="col-md-6 col-sm-12 hiddensm" style={{ backgroundImage: 'url(assets/images/macbook.png)', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '450px' }}></div>
		            </div>
			    </div>
		    </div>
	    </Fragment>
    )
}

export default HomeMedico;