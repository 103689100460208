import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

const Terms = () => {
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h1 className="py-3">Términos y condiciones de uso</h1>
                    <h5>USO DEL SITIO</h5>
                    <p className="contenido_nosotros">
                        MEDPACOM SAPI de CV  ("Medpacom", "nosotros", "nos" o "nuestro") opera el sitio web ubicado en www.medpacom.com y otros sitios web relacionados y aplicaciones móviles con enlaces a estos Términos y Condiciones del Servicio (colectivamente, el "Sitio"). Ofrecemos, entre otros, los servicios de comunicación profesional y privada en línea, que permiten a los profesionales de la salud  ("Doctoras y Doctores Miembros") informar y consultar sobre temas de salud e involucrar a sus pacientes para que puedan obtener una remuneración económica por la prestación de sus servicios ("Servicios"). Al acceder y utilizar el Sitio, usted acepta estar sujeto a estos Términos de uso y a todos los demás términos y políticas que aparecen en el Sitio. Si no desea estar sujeto a ninguno de estos Términos de uso, no puede usar el Sitio o los Servicios de nuestras plataformas.
                    </p>

                    <p className="contenido_nosotros">
                        Estos Términos y Condiciones del Servicio o indistintamente también llamados como “TCDS” regulan el Uso de todos los servicios y productos que ofrece Medpacom, incluyendo todo el contenido del sitio web www.medpacom.com, y de su aplicación para dispositivos móviles de ahora en adelante “Medpacom APP”.
                    </p>

                    <p className="contenido_nosotros">
                        Los servicios prestados por Medpacom APP están sujetos a un conjunto de pautas y condiciones que regulan su uso. El aprovechamiento que un individuo haga de los servicios incluidos en Medpacom APP, sólo se considerará lícito y autorizado cuando lo sea en cumplimiento de las obligaciones impuestas, con los límites, restricciones y alcances aquí delineados, así como los que surjan de disposiciones complementarias o accesorias, y/o de las diferentes normativas legales de orden nacional e internacional cuya aplicación corresponda.
                    </p>

                    <p className="contenido_nosotros">
                        Medpacom, podrá en cualquier momento y sin necesidad de previo aviso modificar estos Términos y Condiciones Generales. Tales modificaciones serán operativas a partir de su fijación en Medpacom APP. Los profesionales de la salud, pacientes, usuarios y/o personas que contraten, utilicen, se suscriban, descarguen y/o  accesen a Medpacom APP deberán mantenerse actualizados en cuanto a los términos aquí incluidos ingresando en forma periódica al apartado de legales de Medpacom APP.
                    </p>

                    <p className="contenido_nosotros">
                        A los profesionales de la salud, pacientes, usuarios y/o personas que contraten, utilicen, se suscriban, descarguen y/o accesen a Medpacom APP les informamos de los Términos y Condiciones Generales, les son aplicables por la simple descarga, uso o acceso a Medpacom APP, por lo que en este acto manifiesta que los aceptan, y se obliga a su cumplimiento.  En el caso de que no estén de acuerdo con los Términos y Condiciones  Generales, deberán abstenerse de descargar, acceder o utilizar Medpacom APP.    Medpacom, se reserva el derecho de modificar discrecionalmente el contenido de Medpacom APP en cualquier momento, sin necesidad de previo aviso.   Los profesionales de la salud, pacientes, usuarios y/o personas que contraten, utilicen, se suscriban, descarguen y/o accedan a Medpacom APP, entendidos estos como aquellas persona que realiza la descarga, el acceso y/o el uso mediante equipo de cómputo, teléfonos inteligentes, tabletas y/o cualesquier otros aparatos de comunicación y/o electrónicos, convienen en no utilizar dispositivos, software, o cualquier otro medio tendiente a interferir tanto en las actividades y/u operaciones de Medpacom APP o en las bases de datos y/o información que se contenga en el mismo.
                        </p>
                    <h5>ACEPTACIÓN DE LAS CONDICIONES</h5>

                        <p className="contenido_nosotros">
                            Salvo acuerdo físico firmado por escrito que pacte lo contrario entre Usted y Medpacom, al utilizar cualquiera de los servicios y/o productos que ofrece Medpacom y/o Medpacom APP, Usted está aceptando los Términos y Condiciones del Servicio en este Convenio y declara expresamente su aceptación en términos de las leyes mexicanas, sometiéndose expresamente a la jurisdicción y competencia de los tribunales localizados en la ciudad de Monterrey, Nuevo León, México.
                    </p>

                        <p className="contenido_nosotros">
                            Así mismo acepta plenamente cualquier política o complemento que pueda ser añadido a estos Términos y Condiciones del Servicio, y acepta cualquier revisión o cambio del que sean objeto.
                    </p>

                        <p className="contenido_nosotros">
                            Si usted está accediendo o utilizando cualquiera de los servicios y/o productos y/o Medpacom APP, en nombre de su empleador o en representación de un tercero ya sea persona física o moral, usted manifiesta y garantiza que tiene la autoridad para actuar en nombre y vincular a su empleador o al tercero que representa a este Convenio de Términos y Condiciones del Servicio, y en todos los puntos de este Convenio donde se refiere a Usted o al Usuario, también incluirá a su Empresa o su Representado.
                    </p>

                        <p className="contenido_nosotros">
                            Los profesionales de la salud, pacientes, usuarios y/o personas que contraten, utilicen, se suscriban, descarguen y/o  accesen a Medpacom APP, reconocen que no todos los servicios y contenidos están disponibles en todas las áreas geográficas y que algunos servicios y contenidos pueden ser utilizados solamente con posterioridad a su contratación, activación o registro previo por los profesionales de la salud, pacientes, usuarios y/o personas que contraten, utilicen, se suscriban, descarguen y/o accesen a Medpacom APP y/o mediante el pago de un precio o contraprestación. Medpacom no garantiza la disponibilidad y continuidad de la operación de Medpacom APP y de los servicios y contenidos, ni la utilidad de Medpacom APP en relación con cualquier actividad específica, independientemente del medio de acceso que utilice las personas incluido la telefonía móvil, incluyendo tabletas y demás dispositivos electrónicos inteligentes. Medpacom no será responsable por daño o pérdida directo, indirecto o consecuencial alguno de cualquier naturaleza que pueda ser causado debido a la falta de disponibilidad o continuidad de operación de Medpacom APP y/o de los servicios y contenidos de la misma.
                    </p>

                        <p className="contenido_nosotros">
                            Los profesionales de la salud, pacientes, usuarios y/o personas que contraten, utilicen, se suscriban, descarguen y/o  accesen a Medpacom APP, que no acepten, se hallen en desacuerdo, o incurrieren en incumplimiento de las disposiciones fijadas por Medpacom en estos Términos y Condiciones Generales, no contará con autorización para el uso de los servicios y contenidos que existen o puedan existir en Medpacom APP, debiendo retirarse de Medpacom APP en forma inmediata, y abstenerse de, descargarla, ingresar o utilizarla nuevamente al mismo.
                    </p>

                        <h5>SERVICIOS DE ATENCIÓN MÉDICO</h5>

                        <p className="contenido_nosotros">
                            Los profesionales de la salud que prestan servicios a través de MEDPACOM, son profesionales independientes y legalmente certificados y acreditados por sus propios concejos médicos de cada especialidad reconocidos por la ley de cada país en lo que ejercen su profesión y  que practican dentro de un grupo de prácticas profesionales de propiedad independiente conocidas colectivamente en nuestra plataforma como "Pro Doctors Medpacom". MEDPACOM SAPI de CV, no ejerce la medicina ni ninguna otra profesión con licencia, y no interfiere con la práctica de la medicina o cualquier otra profesión con licencia por parte de profesionales de la salud; cada uno de los cuales es responsable de sus servicios y del cumplimiento de los requisitos aplicables a su profesión y licencia. Ni Doctor MEDPACOM  ni ningún tercero que promocione los Servicios o le brinde un enlace a los Servicios serán responsables de cualquier asesoramiento profesional que obtenga de un Profesional de la Salud a través de los Servicios de comunicación que se ofrecen dentro de la plataforma (aplicación). Medpacom y Medpacom APP son un vehículo por medio del cual se otorga la facilidad de enlazar a profesionales de la salud con pacientes y bajo ninguna circunstancia se puede entender que nosotros realizamos consultas médicas o similares.
                    </p>

                        <h5>CONTENIDO DEL SITIOS</h5>
                        <p className="contenido_nosotros">
                            Ninguno de los contenidos del Sitio (solo la información que recibe de los profesionales de la salud) debe considerarse un consejo médico o una aprobación, representación o garantía de que cualquier medicamento o tratamiento en particular es seguro, apropiado o efectivo para usted, todas las recetas médicas o concejos que un profesional de la salud recomiende para un usuario  través de la plataforma, es responsabilidad del paciente o usuario, seguir o no las indicaciones que el profesional de la salud recomiende.
                    </p>

                        <h5>CUENTAS DE USUARIO</h5>
                        <p className="contenido_nosotros">
                            Cuando se registra en la plataforma, debe crear una cuenta ("Cuenta") ingresando su nombre, dirección de correo electrónico, contraseña y cierta otra información recopilada por MEDPACOM (colectivamente "Información de la cuenta"). Para crear una cuenta, debe ser mayor de edad para formar un contrato vinculante. Si no es mayor de edad para formar un contrato vinculante, no puede registrarse para usar nuestros Servicios. Usted acepta que la información de la cuenta que nos proporciona en todo momento, incluso durante el registro y en cualquier información que cargue en el sitio, será verdadera, precisa, actual y completa. No puede transferir ni compartir la contraseña de su Cuenta con nadie, ni crear más de una Cuenta (con la excepción de las subcuentas establecidas para los niños de los cuales usted es el padre o tutor legal o de las cuentas vinculadas a la cuenta titular). Usted es responsable de mantener la confidencialidad de la contraseña de su Cuenta y de todas las actividades que ocurran bajo su Cuenta. MEDPACOM se reserva el derecho de tomar cualquier acción, según lo considere necesario o razonable, con respecto a la seguridad del Sitio y la Información de su Cuenta. En ningún caso y bajo ninguna circunstancia, MEDPACOM será responsable ante usted por cualquier responsabilidad o daño que resulte o surja de su uso del Sitio, su uso de la Información de la cuenta o su divulgación de la Información de la cuenta a un tercero. No puede usar la cuenta de otra persona en ningún momento. La información que los usuarios comparten a través de la plataforma es de carácter confidencial y solo podrá ser compartida con previa autorización de los involucrados, MEDPACOM tiene la obligación de respetar la confidencialidad de la información de carácter clínico que se comparten los usuarios.
                    </p>

                        <p className="contenido_nosotros">
                            Se reitera que toda la información proporcionada para crear la Cuenta así como para sus actualizaciones deberá ser fidedigna, completa y exacta y se revela bajo protesta de decir verdad. Cuando la información suministrada no atienda a las circunstancias reales de quien la brinda, se considerará que tal profesional de la salud, paciente y/o usuario se encuentran de forma expresa e inmediata en incumplimiento de los presentes Términos y Condiciones Generales, este siendo el responsable de todos los daños y/o perjuicios que deriven de forma directa o indirecta para Medpacom o terceros como consecuencia de tal falta de veracidad o exactitud.
                    </p>

                        <p className="contenido_nosotros">
                            Una vez creada la Cuenta el profesional de la salud, paciente y/o usuario dispondrán, de un nombre de usuario y una contraseña que le permitirá el acceso personalizado, confidencial y seguro a su cuenta personal dentro de Medpacom APP, cabe mencionar que tanto el profesional de la salud, paciente como los usuarios podrán tener acceso a Medpacom APP mediante sus cuentas en Facebook y/o Twitter, lo anterior sin generar una nuevo nombre de usuario y contraseña a los ya generados para dichas redes sociales. Los Servicios y Contenidos sujetos a la Cuenta han sido concebidos para el uso personal del usuario requirente, por tanto el nombre de usuario y la contraseña de acceso concedidos por Medpacom solo podrán ser usados por este, estando prohibida su utilización por otra persona distinta al mismo. El profesional de la salud, paciente y/o el usuario registrado asumirá la obligación de guarda y custodia de su nombre de usuario y contraseña de acceso, debiendo informar inmediatamente a Medpacom cuando los mismos hubieren perdido su estado de confidencialidad, y/o cuando sean objeto de uso por un tercero.
                    </p>
                        <p className="contenido_nosotros">
                            Cada profesional de la salud, paciente y/o usuario serán responsables de mantener actualizada su información asentada en la Cuenta.
                    </p>
                        <p className="contenido_nosotros">
                            Medpacom se reserva el derecho de (i) rechazar cualquier solicitud alta de Cuenta o registro de nuevos profesionales de la salud, pacientes y/o usuarios dentro de la aplicación o sitio web; o (ii) cancelar, sin responsabilidad alguna, la Cuenta y/o registro de del Usuario, sin causa justificada, y sin que ello genere derecho alguno en beneficio del  Usuario. Por tanto el profesional de la salud, el paciente y/o Usuario renuncian a ejercer acción legal alguna de forma directa o indirecta en contra de Medpacom por los daños y/o perjuicios, directos o indirectos causados por la cancelación y/o uso de la Cuenta o registro de Usuario. Adicionalmente Medpacom, en caso de incumplimiento del profesional de la salud, pacientes y/o usuarios a cualquiera de las estipulaciones de los presentes Términos y Condiciones Generales y de aquellas que se adicionen de tiempo en tiempo podrá cancelar, sin responsabilidad alguna, la Cuenta y/o registro y/o contrato del profesional de la salud, pacientes y/o usuarios.
                    </p>
                        <h5>CAMBIOS Y AVISOS</h5>

                        <p className="contenido_nosotros">
                            Cualquier modificación a los presentes Términos y Condiciones del Servicio será realizada cuando Medpacom lo considere apropiado, siendo exclusiva responsabilidad de Usted asegurarse de tomar conocimiento de tales modificaciones; por tanto, Medpacom no está obligada a notificar a los profesionales de la salud, pacientes y/o Usuarios las actualizaciones, modificaciones y/o gregados de los Términos y Condiciones del Servicio. Por consiguiente, suplicamos a todos los Usuarios a que lean detalladamente este Convenio.
                    </p>


                        <p className="contenido_nosotros">
                            La actualización más reciente de este Convenio está disponible dentro del sitio web de Medpacom, como en Medpacom APP. Los efectos de los posibles cambios entrarán en vigor al momento de ser publicados en cualquiera de los Medpacom APP. Para el caso de alguna posible discrepancia entre la versión en español y la redactada en otro idioma de estos Términos y Condiciones del Servicio, la versión en español prevalecerá.
                    </p>

                        <p className="contenido_nosotros">
                            Si no acepta en forma plena y cabal los Términos y Condiciones del Servicio de este Convenio, o no desea estar limitado por ellos, le recomendamos no acceder o no hacer uso de los Medpacom APP, ni de sus servicios y/o productos.
                    </p>

                        <h5>USO DE LOS SERVICIOS POR MENORES DE EDAD</h5>
                        <p className="contenido_nosotros">
                            Los Servicios están disponibles para el uso de menores de edad con la tutela de los padres o tutores legales, pero los menores de edad no están autorizados a convertirse en Miembros titulares de cuentas y no pueden usar los Servicios sin la supervisión de un padre o tutor legal. Si se registra como padre o tutor legal en nombre de un menor, será totalmente responsable de cumplir con estos Términos de uso.
                    </p>

                        <h5>CONDICIONES DEL SERVICIO.</h5>

                        <p className="contenido_nosotros">
                            Medpacom APP, sus contenidos, servicios y/o productos están disponibles para cualquier persona física o moral, que sea legalmente capaz de suscribir Convenios o acuerdos vinculantes. Como prueba de conformidad con estos criterios, podrían ser requeridos ciertos datos de identificación o información de contacto, previamente o durante el acceso a los Medpacom APP, a sus servicios y/o productos.
                    </p>
                        <p className="contenido_nosotros">
                            Estos Términos y Condiciones del Servicio son establecidos entre Medpacom y Usted. Ninguna otra persona tiene derecho a beneficiarse de ellos.
                    </p>
                        <p className="contenido_nosotros">
                            Para que Usted manifieste su absoluta conformidad respecto al uso de los servicios y/o productos y/o Medpacom APP en estas condiciones, bastará la aceptación de los Términos y Condiciones del Servicio dentro de los Medpacom APP.
                    </p>
                        <p className="contenido_nosotros">
                            Para utilizar los servicios y/o productos contratados, Usted podrá autorizar en los Términos y Condiciones de Servicio de este Convenio, a la(s) persona(s) con las que guarde una relación laboral o profesional para que en su nombre y representación se haga uso de los medios, mecanismo y datos que sean necesarios para utilizar los servicios y/o productos contratados en los Medpacom APP.
                    </p>

                        <h5>ACEPTACIÓN DEL CONVENIO</h5>
                        <p className="contenido_nosotros">
                            El presente Convenio constituye el acuerdo comercial pleno celebrado entre las Partes contratantes en relación con el objeto del mismo y deja sin efecto cualquier otra negociación, acuerdo, obligación o comunicación dada entre las Partes con anterioridad a esta fecha, sea verbal o escrita.
                    </p>

                        <p className="contenido_nosotros">
                            Para esos efectos, Usted reconoce y acepta que el presente Convenio podrá llevarse a acabo mediante la simple aceptación electrónica de los presentes TCDS  dentro del Sitio o de Medpacom APP, lo cual sustituirá a la firma autógrafa de las Partes hecha con anterioridad, garantizándose así la integridad del presente Convenio produciendo los mismos efectos que las leyes otorgan a los documentos con firma autógrafa.
                    </p>

                        <p className="contenido_nosotros">
                            En función de lo anterior este Convenio entre las Partes surte plenos efectos jurídicos al momento de acetar de manera virtual los TCDS.
                    </p>

                        <h5>DERECHOS DE ACCESO</h5>
                        <p className="contenido_nosotros">
                            Por la presente, le otorgamos un derecho limitado, no exclusivo e intransferible para acceder al nuestra plataforma y utilizar los Servicios únicamente para su uso personal no comercial y solo según lo permitido por estos Términos de uso y cualquier otro acuerdo que haya celebrado con nosotros. ("Derechos de acceso"). Nos reservamos el derecho, a nuestro exclusivo criterio, de negar o suspender el uso del Sitio o los Servicios a cualquier persona por cualquier motivo. Usted acepta que no intentará y no intentará: (a) hacerse pasar por otra persona o entidad, o tergiversar su afiliación con una persona o entidad; (b) usar las plataformas o los Servicios para violar cualquier ley local, estatal, nacional o internacional; (c) realizar ingeniería inversa, desensamblar, descompilar o traducir cualquier software u otros componentes del Sitio o los Servicios; (d) distribuir virus u otro código informático dañino a través del Sitio; o (e) usar los Servicios o el Sitio de cualquier otra manera que exceda el alcance de uso otorgado anteriormente. Además, acepta abstenerse de lenguaje y comportamiento abusivos que podrían considerarse inapropiados, o conductas ilegales, cuando se comunique con profesionales de la salud a través del sitio y abstenerse de contactar a profesionales de la salud para servicios de telemedicina fuera de la plataforma. MEDPACOM no es responsable de las interacciones con profesionales de la salud. Recomendamos encarecidamente que no utilice los Servicios en computadoras públicas o dispositivos que no sean de su propiedad. También le recomendamos que no almacene la contraseña de su Cuenta a través de su navegador web u otro software.
                    </p>

                        <p className="contenido_nosotros">
                            a. Usted se obliga a realizar una adecuada utilización de la plataforma tecnológica o software como servicio y a cumplir las obligaciones asumidas mediante la utilización del mismo, ya que Usted es el exclusivo responsable de los hechos o actos de sus empleados, socios, accionistas, administradores, funcionarios, agentes y terceros a los que Usted permita el acceso y/o la utilización de su usuario y contraseña para acceder a la plataforma tecnológica o software.
                    </p>
                        <p className="contenido_nosotros">
                            b. Usted se compromete a no usar, transmitir, difundir o poner a disposición de terceros a través de la plataforma tecnológica o software: i) contenidos o información que de cualquier forma promuevan actitudes delictivas, denigratorias, difamatorias, de riesgo para las personas o discriminatorias o que contengan mensajes y/o elementos delictivos, violentos o pornográficos; ii) contenidos o información que de cualquier forma induzcan al error, violen derechos de propiedad intelectual o industrial pertenecientes a Medpacom o a terceros; iii) contenidos o información que de cualquier forma incorporen virus u otros elementos físicos o electrónicos que puedan dañar o deteriorar la información, la infraestructura, equipos y/o cualquier otro bien tangible o intangible propiedad de Medpacom o de terceros; y iv) contenidos o información que de cualquier forma sean contrarias a la ley, a la moral y buenas costumbres generalmente aceptadas o que alteren o contravengan al orden público.
                    </p>
                        <p className="contenido_nosotros">
                            c. Usted deberá utilizar la plataforma tecnológica o software como servicio exclusivamente para el uso TCDS y nunca para beneficio propio o de terceros para el desarrollo, construcción explotación y/o aprovechamiento comercial, pagado o gratuito, del conocimiento, elementos, herramientas o cualquier desarrollo puesto a su disposición y que sea parte de la propiedad intelectual protegida de Medpacom, quedando bajo la exclusiva responsabilidad de Usted el limitar, restringir, evitar, prohibir e impedir que los Usuarios, su personal o terceros hagan mal uso, abuso o uso no autorizado del Sistema, obligándose a cubrir cualquier cargo, e inclusive a cubrir los daños y perjuicios que se causen a Medpacom y a terceros por este concepto sin perjuicio de las demás acciones legales que procedan a favor de Medpacom, por violación a los derechos de propiedad intelectual o industrial, incluyendo el delito de revelación, aprovechamiento y uso ilegal de secreto industrial, de conformidad con lo dispuesto por la Ley vigente en México.
                    </p>
                        <p className="contenido_nosotros">
                            En todo momento Medpacom tendrá la facultad de determinar bajo su propio criterio la naturaleza y magnitud del incumplimiento por parte de Usted a las obligaciones establecidas en este Convenio y por lo mismo se reserva el derecho de: i) limitarle el acceso a la plataforma tecnológica o software como servicio; ii) suspenderle temporalmente sus derechos como Usuario; o bien, iii) cancelarle en definitiva la cuenta y rescindir el presente Convenio con Usted sin necesidad de resolución judicial alguna y sólo mediante comunicación expresa que al efecto realice Medpacom, ya sea por medios electrónicos o por escrito según lo decida Medpacom.
                    </p>
                        <p className="contenido_nosotros">
                            d. Será exclusiva responsabilidad de Usted el que se realice en todo momento un uso adecuado y diligente de la plataforma tecnológica o software como servicio, siendo el único responsable de las consecuencias derivadas del uso o mal uso que se haga de su usuario, contraseña y cuenta.
                    </p>
                        <p className="contenido_nosotros">
                            Del mismo modo, Usted reconoce y acepta que Medpacom no será responsable por cualquier daño o perjuicio que pudiera sucederle a Usted.
                    </p>
                        <p className="contenido_nosotros">
                            Del mismo modo, Usted reconoce y acepta que Medpacom no será responsable por cualquier daño o perjuicio que pudiera sucederle a su equipo de cómputo propiedad de o utilizado bajo cualquier título legal por parte de Usted ya sea por virus o cualquiera agente nocivo que pueda transmitirse por medios informáticos o electrónicos, o en general, por cualquier otro tipo de daño, menoscabo, perjuicio, pérdida, destrucción o deterioro que le resulte a Usted con motivo de la emisión, recepción, validación, resguardo e intercambio de datos y/o documentos electrónicos en general.
                    </p>

                        <h5>TARIFAS Y CONDICIONES DE COMPRA PARA PACIENTES.</h5>

                        <p className="contenido_nosotros">
                            Usted acepta pagar todas las tarifas o cargos a su Cuenta de acuerdo con las tarifas, los cargos y los términos de facturación vigentes en el momento en que una tarifa o cargo es pagadero. Al proporcionar a MEDPACOM su número de tarjeta de crédito o cuenta de PayPal o cualquier otro medio de pago vigente en nuestra plataforma e información de pago asociada, usted acepta que MEDPACOM está autorizado a facturar de inmediato a su cuenta todas las tarifas y cargos adeudados y pagaderos a MEDPACOM a continuación y que no Se requiere aviso o consentimiento. Si su plan de salud, empleador o agencia ha acordado con MEDPCOM pagar la tarifa o cualquier parte de la tarifa, o si la tarifa es conforme a algún otro acuerdo con MEDPACOM, ese ajuste de tarifa se reflejará en la tarifa que usted es finalmente acusado Consulte con su empleador, plan de salud o agencia para determinar si se reembolsará algún Servicio.
                    </p>
                        <p className="contenido_nosotros">
                            Si no tiene cobertura de seguro para los Servicios que se ofrecen a través de MEDPACOM, o si su cobertura es denegada, usted reconoce y acepta que será personalmente responsable de todos los gastos incurridos. MEDPACOM no ofrece ninguna garantía de que recibirá dicho reembolso. MEDPACOM se reserva el derecho que tienen los Profesionales de la salud de modificar o implementar una nueva estructura de precios en cualquier momento antes de facturarle su pago inicial o los pagos futuros adeudados de conformidad con estos Términos de uso. Usted comprende y acepta que por los Servicios proporcionados con pago anticipado por consulta, será responsable de una tarifa de consulta solicitada igual a la totalidad o una parte de las tarifas que usted y su asegurador u otro pagador habrían pagado por los servicios programados si no cancela la petición de la consulta una cita programada con al menos 24 horas de anticipación, a menos que le notifiquemos por escrito que se aplica un período de cancelación más corto. Los precios y costos de la consulta a distancia son establecidos por cada uno de los Profesionales de la salud, es de su entera libertad el costo de la tarifa, tiempo de la consulta y término de la misma o en su caso que sea gratuita.
                    </p>
                        <p className="contenido_nosotros">
                            Usted acepta que el precio o las tarifas se incrementará conforme lo determine Medpacom sin más requerimiento que emitir el aviso digital correspondiente dentro de sus plataformas y/o aplicaciones digitales, reconociendo también que la aplicación automática del nuevo precio o tarifa no requería de la firma o aceptación de un nuevo Convenio y que en caso de que se lleve a cabo la prórroga del presente Convenio, Usted acepta que para tales efectos, será considerado como precio o tarifa de los Servicios el que para la fecha de la prorroga estén publicados en los Medpacom APP o en el Sitio.
                    </p>
                        <h5>PROFESIONALES DE LA SALUD.</h5>
                        <p className="contenido_nosotros">
                            Los profesionales de la salud son las Doctoras y los Doctores aceptados como miembros participantes y usuarios de MEDPACOM y de Medpacom App. En este acto dichos profesionales manifiestan que:
                    </p>
                        <p className="contenido_nosotros">
                            (i)	Están autorizados para practicar la medicina y cuentan con todas las autorizaciones legales y académicas necesarias en su rama de especialidad;
                    </p>
                        <p className="contenido_nosotros">
                            (ii)	Las consultas médicas, recetas y consejos que dan a los pacientes y/o usuarios son de su estricta responsabilidad ya que Medpacom App es solo un medio material que facilita la interacción con sus pacientes de manera virtual;
                    </p>
                        <p className="contenido_nosotros">
                            (iii)	Aceptan que en cualquier momento Medpacom y/o Medpacom App podrá eliminarlos de su red de profesionales de la salud;
                    </p>
                        <p className="contenido_nosotros">
                            (iv)	Los apartados de DESCARGO DE RESPONSABILIDAD DE GARANTÍAS, LIMITACIÓN DE RESPONSABILIDAD e INDEMNIZACIÓN establecidos en los presentes TCDS serán aplicables Mutatis Mutandi a favor de MEDPACOM y Medpacon APP por parte de los profesionales de la salud; por tanto, MEDPACOM y Medpacon APP quedan totalmente liberados de cualquier responsabilidad y los profesionales de la salud en este acto renuncian a ejercer cualquier acción legal en contra de estas, sus accionistas, consejeros, directores, funcionarios y/o representantes;
                    </p>
                        <p className="contenido_nosotros">
                            (v)	La relación con MEDPACOM y Medpacon APP es estrictamente comercial y mercantil, ya que se encuentran utilizando un medio material pata facilitar la interacción virtual con sus pacientes. Por lo que son partes contratantes totalmente independientes, por lo tanto no existe relación obrero-patronal entre estos. Los Profesionales de la Salud se obligan a mantener a todo el personal que empleen para la prestación de los Servicios (personal propio y externo) se encuentre debidamente inscrito en el Instituto Mexicano del Seguro Social (“IMSS”) y en el Instituto del Fondo Nacional de la Vivienda para los Trabajadores (“INFONAVIT”) y afiliado en el Instituto del Fondo Nacional para el Consumo de los Trabajadores, así como a observar y cumplir con lo dispuesto en el Reglamento Federal de Seguridad, Higiene y Medio Ambiente de Trabajo que Los Profesionales de la Salud manifiestan conocer, así como a finiquitar ante el IMSS, en tiempo y forma, las cuotas obrero-patronales que correspondan;Los profesionales de la Salud deberán proporcionar a MEDPACOM y/o Medpacom App, al simple requerimiento de ésta última, todas y cada uno de los comprobantes de pago y demás documentación aplicable que hagan constar el cumplimiento a las obligaciones que por su carácter de patrón le corresponden de conformidad con la legislación aplicable, ya que el incumplimiento a lo señalado en el presente párrafo será causa suficiente para que MEDPACOM y/o Medpacom App elimine a dicho profesional de la salud de su red de profesionales. Sin perjuicio de lo señalado anteriormente, los profesionales de la salud serán responsables de todas y cada una de las obligaciones que por su personal le impone el IMSS, el INFONAVIT, el Sistema de Ahorro para el Retiro de los Trabajadores y las autoridades hacendarias y laborales, ya sean de índole municipal, estatal o federal;
                    </p>
                        <p className="contenido_nosotros">
                            (vi)	El profesional se obliga a pagar a MEDPACOM y/o Medpacom App como contraprestación por el uso del Sitio y las aplicaciones propiedad de estos y los beneficios que estos otorgan, los montos y/o porcentajes que nosotros informemos por cualquier medio con soporte material; y
                    </p>
                        <p className="contenido_nosotros">
                            (vii)	Aceptan y se obligan conformes estos TCDS en el entendido que los mismos les aplicaran de forma total y sin restricción alguna Mutatis Mutandi en favor de MEDPACOM y/o Medpacom App.
                    </p>
                        <p className="contenido_nosotros">
                            (viii)	No podrán participar en aplicaciones, sitios web y/o software que puedan competir con MEDPACOM y/o Medpacom App.
                    </p>
                        <p className="contenido_nosotros">
                            (ix)	Desarrollaran sus consultas con el profesionalismo de siempre, respetando la integridad del paciente y brindando su mejor consejo posible.
                    </p>

                        <h5>TERMINACIÓN</h5>
                        <p className="contenido_nosotros">
                            Puede desactivar su cuenta y finalizar su registro en cualquier momento, por cualquier motivo, enviando un correo electrónico a soporte@medpacom.com. O directamente en la opción de eliminar cuenta en el menú de configuración de la plataforma. MEDPACOM puede suspender o cancelar su uso del Sitio, su Cuenta y / o registro por cualquier motivo en cualquier momento. Sujeto a la ley aplicable, MEDPACOM se reserva el derecho de mantener, eliminar o destruir todas las comunicaciones y materiales publicados o cargados en el Sitio de conformidad con sus políticas internas de retención de registros y / o destrucción de contenido. Después de dicha terminación, MEDPACOM no tendrá más obligación de proporcionar los Servicios, excepto en la medida en que estemos obligados a proporcionarle acceso a sus registros de salud o se requiera que los Profesionales de la Salud le brinden atención continua de acuerdo con sus leyes, éticas y legales aplicables en el país donde se llevó acabo la consulta, es decir, el país de residencia del profesional de la salud. Las obligaciones de los profesionales de la salud a través de nuestras plataformas son para con usted.
                    </p>
                        <h5>DERECHO A MODIFICAR.</h5>
                        <p className="contenido_nosotros">
                            Podemos, a nuestro exclusivo criterio, cambiar, agregar o eliminar partes de estos TDCS en cualquier momento de forma progresiva. El uso continuo del Sitio y / o los Servicios después de la notificación de dichos cambios indicará su reconocimiento de dichos cambios y su acuerdo de estar sujeto a los TCDS revisados, incluidos dichos cambios.
                    </p>
                        <h5>DESCARGO DE RESPONSABILIDAD DE GARANTÍAS.</h5>
                        <p className="contenido_nosotros">
                            USTED ACEPTA EXPRESAMENTE QUE EL USO DE NUESTRAS PLATAFORMAS O DE LOS SERVICIOS ES BAJO SU PROPIO RIESGO Y RESPONSABILIDAD. NUESTRAS PLATAMORMAS Y LOS SERVICIOS SE PROPORCIONAN "TAL CUAL" Y "SEGÚN DISPONIBILIDAD". MEDPACOM RECHAZA EXPRESAMENTE TODAS LAS GARANTÍAS DE CUALQUIER TIPO, EXPLÍCITAS O IMPLÍCITAS, INCLUYENDO, PERO SIN LIMITARSE A, CUALQUIER GARANTÍA DE COMERCIABILIDAD, APTITUD PARA UN USO O PROPÓSITO EN PARTICULAR, NO INFRACCIÓN, TÍTULO, OPORTUNIDAD, CONDICION, OPORTUNIDAD, VALOR, OPTIMIZACIÓN EXACTITUD DE DATOS E INTEGRACIÓN DEL SISTEMA.
                    </p>

                        <h5>LIMITACIÓN DE RESPONSABILIDAD.</h5>
                        <p className="contenido_nosotros">
                            EN ESTE ACTO USTED ESTA DE ACUERDO, SE OBLIGA Y POR TANTO CONSCIENTE QUE BAJO NINGUNA CIRCUNSTANCIA  MEDPACOM, MEDPACOM APP, MEDPACOM PROFESIONALES O SUS OFICIALES, EMPLEADOS, DIRECTORES, PADRES, SUBSIDIARIAS, AFILIADOS, AGENTES O LICENCIANTES SERÁN RESPONSABLES DE CUALESQUIER DAÑOS, PERJUICIOS, PÉRDIDAS, ENFERMEDADES, DEFUNCIONES, INCAPACIDADES Y/O CUALESQUIER OTROS DAÑOS CONSECUENTES O EJEMPLARES, INCLUIDOS, PERO SIN LIMITARSE A, DAÑOS POR PÉRDIDA DE INGRESOS, POR PÉRDIDA DE LA SALUD, BENEFICIOS, BUENA VOLUNTAD, USO, DATOS U OTRAS PÉRDIDAS TANGIBLES Y/O INTANGIBLES DERIVADAS O RELACIONADAS CON SU USO DEL SITIO O DE LOS SERVICIOS, CON RESPECTO A DONDE ESTÁN DICHOS DAÑOS EN CONTRATO, TORT (INCLUYENDO NEGLIGENCIA Y RESPONSABILIDAD ESTRICTA), GARANTÍA, ESTATUTO O DE OTRO MODO.
                    </p>
                        <p className="contenido_nosotros">
                            En la medida en que no podamos, como una cuestión de ley aplicable, renunciar a cualquier garantía implícita o limitar sus responsabilidades, el alcance y la duración de dicha garantía y el alcance de nuestra responsabilidad serán los mínimos permitidos por dicha ley aplicable.
                    </p>
                        <h5>INDEMNIZACIÓN.</h5>
                        <p className="contenido_nosotros">
                            Usted acepta indemnizar, defender, sacar en paz y a salvo  y eximir de responsabilidad a MEDPACOM, a los PROFESIONALES DE LA SALUD  y a sus funcionarios, directores, empleados, agentes, subsidiarias, afiliados, otorgantes de licencias y proveedores, de forma inofensiva y contra cualquier reclamo, acción, demanda, responsabilidad y liquidación, incluidos, entre otros, honorarios legales y contables razonables ("Reclamaciones"), resultantes de, o supuestamente resultado de, su violación de estos TCDS y/o de los Servicios.
                    </p>
                        <h5>RESTRICCIONES GEOGRÁFICAS.</h5>
                        <p className="contenido_nosotros">
                            MEDPACOM no garantiza que todos los productos, servicios y / o materiales descritos en el Sitio, o los Servicios disponibles a través del Sitio, sean apropiados o estén disponibles para su uso en ubicaciones fuera de México o todos los estados y territorios dentro del territorio mexicano, los pacientes que consulten a los profesionales de la salud fuera de este territorio, están obligados a cumplir con las leyes y normas establecidas por la Constitución de México y de las leyes que de ella emanen.
                    </p>

                        <h5>INFORMACIÓN CONFIDENCIAL.</h5>

                        <p className="contenido_nosotros">
                            Bajo los TCDS de este Convenio Usted o la(s) persona(s) a que Usted otorgue acceso o Usuarios se comprometen a no proporcionar a Medpacom información confidencial o reservada de terceros. Cualquier información o dato que usted envíe o alimente en los Medpacom APP será considerado NO confidencial y usted acepta expresamente que la responsabilidad de la divulgación o publicidad de dicha información es exclusivamente de Usted, aceptando totalmente las obligaciones y consecuencias que recaigan por el uso y manejo de dicha información o contenido.
                        </p>

                        <p className="contenido_nosotros">
                            Usted y Medpacom se obligan mutuamente a guardar con carácter de confidencial toda la información técnica, comercial y/o administrativa relativa a negocios, planes, clientes, productos, y/o tecnología, a la que mutuamente tengan acceso en virtud del presente Convenio y incluyendo la información que sea escuchada y/o observada, incluyéndose de manera enunciativa los procedimientos, normas y/o políticas existentes en Medpacom, los datos, secretos industriales, procesos, fórmulas, manuales, estrategias, métodos, procedimientos de operación, proyectos, negociaciones, tecnología, técnicas, propuestas, conceptos, inventos, descubrimientos, diseños, considerados propiedad de Usted y/o Medpacom, inclusive después de que termine el mismo, siendo responsables en todo momento de cualquier mal uso que den a esa información y/o documentos que origine un daño y/o perjuicio a la otra parte.
                        </p>

                        <p className="contenido_nosotros">
                            A partir de la celebración del presente Convenio, Usted y Medpacom permitirán una a la otra el acceso a información verbal, escrita o que conste en documentos, medios electrónicos o magnéticos, discos ópticos, microfilmes, películas u otros instrumentos similares, que sean del interés para el cumplimiento del objeto del presente Convenio.
                        </p>

                        <p className="contenido_nosotros">
                            Así también, ambas partes se obligan a abstenerse de efectuar operaciones en beneficio propio o de terceros relacionadas con el uso de la información y/o documentación referida en el párrafo que antecede, mientras la citada información y/o documentación tenga el carácter de confidencial. No estará sujeta a confidencia aquella información y/o documentación que sea del dominio público, la que resulte evidente para un técnico en la materia, la que deba ser divulgada por disposición legal u orden judicial o la que al momento de la celebración del presente Convenio se encuentre en posesión de Las Partes.
                        </p>

                        <p className="contenido_nosotros">
                            Se considerará del dominio público aquella información y/o documentación a la que tengan o hayan tenido libre acceso terceras personas o haya sido divulgada a través de cualquier medio de comunicación masiva.
                        </p>

                        <p className="contenido_nosotros">
                            Usted y Medpacom únicamente proporcionarán información a terceros cuando sea solicitado por autoridad competente para ello, debiéndose proporcionar únicamente la Información que le haya sido requerida.
                        </p>

                        <p className="contenido_nosotros">
                            El presente acuerdo de confidencialidad sobrevivirá a la terminación de sus derechos de acceso al Plan de Servicio Contratado. En caso de terminación del Plan de Servicio Contratado, Usted deberá dejar de utilizar la información confidencial y devolver a Medpacom o destruir toda la Información Confidencial en su posesión o control.
                        </p>

                        <p className="contenido_nosotros">
                            No obstante lo anterior, Medpacom se reserva el derecho de citar y/o mencionarlo a Usted como Cliente para efecto de las gestiones de tipo comercial y de mercadotecnia que de manera rutinaria realice en eventos, presentaciones e incluso en medios electrónicos y/o impresos.
                        </p>

                        <h5>RESGUARDO DE LA INFORMACIÓN.</h5>
                        <p className="contenido_nosotros">
                            Usted entiende y acepta que Medpacom recopila, utiliza, almacena y procesa su información personal y pueden compartir de forma onerosa o gratuita dicha información con cualquier terceros que estime conveniente para cualquier fin lícito.
                            </p>
                        <p className="contenido_nosotros">
                            Los datos a ser procesados serán en todo momento los otorgados por Usted o los Usuarios designados y autorizados a fin de contratar y recibir el Servicios. Todos los datos personales serán tratados confidenciales y de conformidad con La Ley Federal de Protección de Datos Personales en Posesión de Particulares y demás legislación aplicable en México.
                        </p>
                        <p className="contenido_nosotros">
                            Medpacom manifiesta que ha procurado instalar y actualizar aquellos otros medios y medidas técnicas adicionales a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de la información de Usted.
                        </p>
                        <p className="contenido_nosotros">
                            En particular, Medpacom utiliza sistemas de seguridad, que permiten comprobar la autenticidad de los datos, así como su integridad y confidencialidad durante su transmisión o intercambio; sin embargo, Usted debe ser consciente y acepta que las medidas de seguridad en Internet no son inexpugnables y por lo mismo, deberá adoptar por su propia cuenta las medidas de seguridad y precauciones que estimen pertinentes en el acceso a la plataforma tecnológica o software.
                        </p>
                        <h5>ELEMENTOS DE SEGURIDAD.</h5>

                        <p className="contenido_nosotros">
                            Usted acepta y reconoce que la Cuenta y la contraseña conforman los Elementos de Seguridad  que le corresponden exclusivamente a cada Usuario y que estarán bajo el control exclusivo de cada uno de ellos.
                        </p>
                        <p className="contenido_nosotros">
                            Adicionalmente, Usted acepta y reconoce que deberá actuar con diligencia y cuidado en el uso de los Elementos de Seguridad, la plataforma tecnológica o software como servicio y el Plan de Servicio Contratado en lo general, asimismo que deberá establecer las medidas razonables para evitar la utilización no autorizada de los Elementos de Seguridad y la plataforma tecnológica o software como servicio por parte de terceros, ya que Usted será el exclusivo responsable de las consecuencias jurídicas y obligaciones que se deriven para él mismo por no cumplir oportunamente con las obligaciones a que se refiere el presente Convenio, independientemente de que sea esto originado por Usted o alguno de los Usuarios o terceros a quien Usted les confió el acceso de la plataforma tecnológica o software.
                            Usted, previamente a la firma o aceptación del presente Convenio, reconoce y acepta haber sido informado y advertido de los alcances y consecuencias legales del adecuado o inadecuado uso de los Elementos de Seguridad, de la plataforma tecnológica o software, manifestando su conformidad en hacer uso de ellos en los términos que se describen en este instrumento y asumiendo en consecuencia la responsabilidad que de dicho uso se derive, en razón de cumplir con los niveles de seguridad y certidumbre que sus necesidades requieren, eximiendo y sacando en paz y a salvo en todo caso a Medpacom por cualquier obligación, carga, reclamación, culpa o responsabilidad que resulte por el incumplimiento, negligencia, extravío, falta de cuidado, uso no autorizado o falta de control en el uso que se les dé a dichos Elementos de Seguridad, de la plataforma tecnológica o software.
                        </p>
                        <p className="contenido_nosotros">
                            Así mismo Usted reconoce que ha enterado particularmente de lo anterior y en lo general de lo establecido en los presentes TCDS a cada uno de los Usuarios.
                        </p>
                        <p className="contenido_nosotros">
                            Las Partes reconocen que el uso de los Elementos de Seguridad, corresponde a la definición que las disposiciones legales vigentes hacen con respecto al valor probatorio de la firma autógrafa, por lo que producirán los mismos efectos legales que las leyes le otorgan a la manifestación de la voluntad por medios tradicionales o escritos.
                        </p>

                        <h5>SERVICIOS PRESTADOS POR TERCEROS.</h5>

                        <p className="contenido_nosotros">
                            Para el caso de que Usted contrate los servicios prestados por los profesionales de la salud que conforman la red de profesionales de Medpacom, Usted se da por enterado y acepta que Medpacom única y exclusivamente aporta su plataforma tecnológica o software como medio para la prestación del servicio impartido por el profesional de la salud y solicitado por Usted.
                        </p>
                        <p className="contenido_nosotros">
                            Asimismo, Usted reconoce y acepta que para la contratación de algún Servicio de Medpacom ha cumplido previamente con los requerimientos legales, fiscales y técnicos que le imponen la legislación aplicable en el país donde Usted está establecido.
                        </p>
                        <p className="contenido_nosotros">
                            Las Partes reconocen que Medpacom no incurre en ninguna responsabilidad, incluyendo cuando la información proporcionada por Usted en la plataforma tecnológica o software como servicio no es veraz, correcta y/o exacta.
                        </p>
                        <p className="contenido_nosotros">
                            Usted se obliga expresamente a sacar en paz y a salvo a Medpacom, así como a indemnizarla, lo mismo que a sus empleados, socios, accionistas, administradores, funcionarios, agentes, representantes, proveedores y distribuidores, de cualquier daño, perjuicio, reclamación, demanda o acción, auditoria o investigación legal que se presente en contra de o se practique a Medpacom en relación con las obligaciones de Usted, y en general, de cualquier daño, perjuicio, reclamación, demanda, auditoria, investigación o acción legal derivada del uso de la plataforma tecnológica o software como servicio con hechos relacionados atribuibles a Usted.
                        </p>
                        <h5>DIVULGACIONES</h5>
                        <p className="contenido_nosotros">
                            Todos los profesionales de la salud que prestan servicios clínicos con licencia en el sitio poseen las licencias profesionales emitidas por las juntas o Concejos médicos que otorgan los certificados o licencias profesionales en los estados donde ejercen. Todos los médicos y psicólogos tienen títulos avanzados en medicina o psicología y han recibido capacitación especializada en cada una de las ramas que ellos promulgan al ser parte de la red de profesionales de MEDPACOM. Puede informar una queja relacionada con la atención brindada por un profesional de la salud comunicándose con MEDPACOM a través de nuestra plataforma. En una relación profesional médico – paciente, la información que ellos comparten entre sí, es de uso confidencial y es resguardada por las leyes mexicanas, el expediente médico es propiedad del paciente y puede ser compartida por el mismo para los usos que él requiera dentro y fuera de la plataforma, sin tener MEDPACOM responsabilidad alguna por el uso que terceras personas puedan darle a esa información, solo el usuario tiene acceso a la misma y el decide que uso le puede dar.
                        </p>
                        <p className="contenido_nosotros">
                            MEDPACOM mantendrá de forma segura cualquier registro clínico creado como resultado de su uso de los Servicios en nombre de los Profesionales de la salud que usan nuestras plataformas, durante un período que no sea inferior al número mínimo de años que dichos registros deben mantenerse bajo el estado y la ley federal de salud y la leyes correspondientes a la información privada, que generalmente es de al menos cinco años. La información almacenada en MEDPACOM está disponible en caso de que una autoridad debidamente sustentada por la ley, la requiera de manera formal para el uso que la autoridad considere necesario en caso de alguna discrepancia entre usuarios.
                        </p>
                        <h5>ENLACES A SITIOS WEB.</h5>
                        <p className="contenido_nosotros">
                            NO SEREMOS RESPONSABLES POR NINGUNA INFORMACIÓN, SOFTWARE O ENLACES ENCONTRADOS EN NINGÚN OTRO SITIO WEB, UBICACIÓN DE INTERNET O FUENTE DE INFORMACIÓN, NI POR SU USO DE DICHA INFORMACIÓN, SOFTWARE O ENLACES, NI POR LOS ACTOS U OMISIONES DE DICHOS SITIOS WEB O SUS RESPECTIVOS OPERADORES.
                        </p>
                        <h5>PROPIEDAD.</h5>

                        <p className="contenido_nosotros">
                            Las plataformas de MEDPACOM y todo su contenido, características y funcionalidad (incluyendo, entre otros, toda la información, software, texto, pantallas, imágenes, video y audio, y el diseño, selección y disposición de los mismos) son propiedad de Medpacom Sapi de CV, sus licenciantes u otros proveedores de dicho material y están protegidos por derechos de autor, marca registrada, patente, secreto comercial y otras leyes de propiedad intelectual o derechos de propiedad intelectual de los Estados Unidos Mexicanos e internacionales. Estos Términos de uso le permiten usar las plataformas únicamente para su uso personal y no comercial. No debe reproducir, distribuir, modificar, crear obras derivadas de, mostrar públicamente, realizar públicamente, volver a publicar, descargar, almacenar o transmitir ningún material en nuestro Sitio, excepto según lo permitido de manera general y ordinaria a través de las plataformas de acuerdo con estos Términos de uso. No debe acceder ni utilizar para ningún fin comercial ninguna parte de las plataformas o ni ningún servicio o material disponible a través del Sitio.
                            </p>

                        <p className="contenido_nosotros">
                            La copia no autorizada, distribución, modificación, exhibición pública o ejecución pública de obras con derechos de autor constituye una infracción de los derechos de los titulares de derechos de autor. Usted acepta que no utilizará los contenidos de los Medpacom APP para infringir los derechos de propiedad intelectual de Medpacom o de terceros. No se debe quitar, sobreimpresión o estropea cualquier aviso de copyright, marca comercial, logotipo, leyenda, u otro aviso de propiedad de los originales o copias de los servicios y/o productos de Medpacom o de proveedores o aliados o asociados de este.
                            </p>

                        <p className="contenido_nosotros">
                            Usted reconoce expresamente que todos y cada uno de los componentes e información que integran la plataforma tecnológica o software como servicio, son propiedad exclusiva de Medpacom y que esta le permitirá hacer uso del mismo de conformidad con lo estipulado en el presente Convenio, por lo que bajo ninguna circunstancia podrá considerarse que se otorga derecho alguno de exclusividad o licenciamiento exclusivo en favor de Usted.
                            </p>

                        <p className="contenido_nosotros">
                            En todo momento Medpacom mantendrá la titularidad de la propiedad industrial y/o intelectual que a la fecha posea, incluyendo de manera enunciativa mas no limitativa, marcas, patentes, secretos industriales, licencias y derechos de autor, y que el presente Convenio no constituye una cesión de derechos de dicha propiedad industrial o intelectual.
                            </p>

                        <p className="contenido_nosotros">
                            Para efectos del presente Convenio, el término Propiedad Intelectual incluye todas las marcas registradas y/o usadas en México o en el extranjero por Medpacom, así como todo derecho sobre invenciones (patentadas o no), diseños industriales, modelos de utilidad, información confidencial, nombres comerciales, avisos comerciales, reservas de derechos, nombres de dominio, software, así como todo tipo de derechos patrimoniales sobre obras y creaciones protegidas por derechos de autor y demás formas de propiedad industrial o intelectual reconocida o que lleguen a reconocer las leyes correspondientes.
                        </p>

                        <p className="contenido_nosotros">
                            Usted se obliga a no usar, comercializar, revelar a terceros, distribuir, regalar, o de cualquier otro modo disponer, por cualquier vía, de cualquier desarrollo de Medpacom, ni de cualquier material o documento que sea resultado de la Propiedad Intelectual de Medpacom.
                        </p>

                        <h5>MARCAS REGISTRADAS.</h5>
                        <p className="contenido_nosotros">
                            Algunos de los nombres, logotipos y otros materiales que se muestran en nuestras plataformas tecnológicas o en los Servicios pueden constituir marcas comerciales, nombres comerciales, marcas de servicio o logotipos ("Marcas") de MEDPACOM u otras entidades. No está autorizado a utilizar dichas Marcas sin el permiso expreso por escrito de MEDPACOM. La propiedad de todas esas Marcas y la buena voluntad asociada con ellas permanecen con nosotros o con esas otras entidades.
                        </p>

                        <h5>PRIVACIDAD Y COMUNICACIONES.</h5>

                        <p className="contenido_nosotros">
                            Se requiere que MEDPACOM cumpla con las leyes federales de privacidad y seguridad de la salud y mantenga salvaguardas para proteger la seguridad de su información de salud. Además, la información que proporciona a sus profesionales de la salud durante una consulta médica o sesión de terapia es legalmente confidencial, excepto por ciertas excepciones legales que se describen con mayor detalle en nuestra Política de privacidad. Dedicamos un esfuerzo considerable a garantizar que su información personal esté segura. La información sobre nuestro uso de la salud y otra información personal se proporcionan en nuestra Política de privacidad.
                        </p>
                        <p className="contenido_nosotros">
                            Como parte de proporcionarle los Servicios, es posible que necesitemos proporcionarle ciertas comunicaciones, como recordatorios de citas, anuncios de servicio, avisos de privacidad, mensajes administrativos y otras comunicaciones sobre los Servicios ("Comunicaciones"). Estas comunicaciones se consideran parte de los Servicios y su Cuenta.
                        </p>
                        <p className="contenido_nosotros">
                            Usted comprende que al marcar la casilla "aceptar" estos Términos de uso y / o cualquier otro formulario que se le presente en el Sitio, usted acepta estos Términos de uso y que dicha acción constituye una firma legal. Usted acepta que podemos enviarle Comunicaciones por medios electrónicos que incluyen, entre otros: (1) por correo electrónico, utilizando la dirección que nos proporcionó durante el registro, (2) mensaje de texto del servicio de mensajes cortos ("SMS") al número de teléfono móvil que nos proporcionó durante el registro ("Mensajes SMS"), (3) notificaciones push en su tableta o dispositivo móvil, o (4) publicando Comunicaciones en el Sitio. La entrega de nuestras Comunicaciones es efectiva cuando la enviamos, independientemente de si leyó la Comunicación. Puede retirar su consentimiento para recibir Comunicaciones desactivando su Cuenta. Si bien la mensajería electrónica segura siempre se prefiere al correo electrónico inseguro, en ciertas circunstancias, la comunicación de correo electrónico insegura que contiene información de salud personal puede tener lugar entre usted y su Doctor particular o la plataforma. MEDPACOM no puede garantizar la seguridad o confidencialidad de los mensajes enviados por correo electrónico. La información relacionada con su atención, incluidas las notas clínicas y los registros médicos, se almacena en servidores seguros y encriptados mantenidos por MEDPACOM y AMAZON WEB SERVICES.
                        </p>

                        <h5>INFORMACIÓN RELEVANTE</h5>

                        <p className="contenido_nosotros">
                            Estos Términos de uso y su uso del Sitio se regirán por las leyes del Estado de Nuevo León en México, sin dar efecto a los principios de conflicto de leyes. CUALQUIER DISPUTA QUE SURJA BAJO CUALQUIER FORMA DE ESTOS TÉRMINOS DE USO SE RESOLVERÁ EXCLUSIVAMENTE POR ARBITRAJE FINAL Y VINCULANTE EN EL ESTADO DE NUEVO LEÓN, MÉXICO, BAJO LAS NORMAS  VIGENTES  EN CUALQUIER TRIBUNAL CORRESPONDIENTE SEGÚN EL CASO. Las partes aceptan la jurisdicción personal y de la materia y el lugar de los tribunales ubicados en Nuevo León, México, para cualquier acción relacionada con estos Términos de uso.
                        </p>
                        <p className="contenido_nosotros">
                            Ninguna renuncia por parte de MEDPACOM de ningún término o condición establecida en estos Términos de uso se considerará una renuncia adicional o continua de dicho término o condición o una renuncia de cualquier otro término o condición, y cualquier incumplimiento por parte de MEDPACOM un derecho o disposición bajo estos Términos de uso no constituirá una renuncia a dicho derecho o disposición. Si un tribunal u otro tribunal de jurisdicción competente consideran que alguna disposición de estos Términos de uso es inválida, ilegal o inaplicable por cualquier motivo, dicha disposición se eliminará o limitará en la medida mínima de manera que las disposiciones restantes de los Términos de El uso continuarán con toda su fuerza y efecto.
                        </p>

                        <p className="contenido_nosotros">
                            Envíe cualquier pregunta o informe cualquier violación de estos Términos de uso a
                        </p>
                        <p className="font-weight-bold">
                            legal@medpacom.com
                        </p>
                        <p className="contenido_nosotros mb-5 pb-5">
                            Gracias por su confianza.
                            Lic. Javier Edgardo Nuñez González
                            CLO/MEDPACOM
                        </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Terms;