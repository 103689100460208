import React from 'react'
import { Container, Row } from 'react-bootstrap'

const About = () => {
    return (
        <Container>
            
            <h5 className="pt-5">QUIENES SOMOS</h5>

            <blockquote className="heading post contenido_nosotros">
                Medpacom está formado por un equipo de profesionales comprometidos con los doctores, doctoras y sus pacientes, en todos los países donde tenemos presencia.
            </blockquote>

            <h5 className="pt-5">¿CÓMO FUNCIONA?</h5>

            <p className="contenido_nosotros">
                A través de nuestra aplicación móvil, tendrás acceso a un medio de comunicación exclusivo y diseñado para los profesionales de la salud certificados, ahí podrás comunicarte con tus pacientes de forma virtual y privada, Medpacom fue diseñado para ser un medio formal para interactuar en una forma sencilla por medio de chat, llamada o video llamada eliminando los medios informales de comunicación y generando confianza entre tus pacientes al igual que  honorarios por consulta a distancia si así lo consideras en cada interacción. En Medpacom tendrás múltiples opciones para administrar su tiempo y sus ingresos, tendrá una perspectiva de cada uno de sus pacientes y contarás con el servicio de intranet para estar en comunicación con sus colegas.
            </p>

            <h5 className="pt-5">¿CUÁNTO CUESTA MEDPACOM?</h5>

            <p className="contenido_nosotros">
                Nuestra plataforma es totalmente gratuita, no cuenta con una renta mensual, anual o similar, al contrario, los profesionales de la salud son los dueños del 90% de los ingresos que la compañía percibe a través de los ingresos que el profesional de la salud genera en la plataforma, solo el 10% será el monto destinado para nuestras funciones operativas, ingresos y desarrollo tecnológico. Siempre será nuestra filosofía el ser una pequeña parte de tu increíble trabajo.
            </p>

            <h5 className="pt-5">NUESTROS INICIOS</h5>

            <p className="contenido_nosotros">
                Desde el año 2015, nos dimos a la tarea de validar y diseñar un sistema profesional de comunicación médico paciente, con el fin de poder cubrir los honorarios por las consultas a distancia que nuestros doctores de confianza realizaron a miembros de la familia, esto con la finalidad de agradecer de una manera justa, el tiempo y la dedicación que siempre recibimos de parte de ellos. Es así como nace Medpacom, un sistema profesional donde mi doctor de confianza pueda tener una comunicación profesional y una remuneración justa por su tiempo y compromiso para mejorar la vida de sus pacientes.
            </p>

            <p className="contenido_nosotros">
                Creamos un consejo médico para que todas las ideas surgieran de las experiencias de cada uno de los profesionales de la salud y sus colegas, así sería una plataforma digital creada por doctores y doctoras, para el uso de ellos y sus pacientes.
            </p>

            <p className="contenido_nosotros">
                No fue fácil diseñar una herramienta de trabajo que cumpliera con los estándares de calidad y el compromiso que su profesión amerita, pero sabemos que todos los días nos esforzamos por brindarles un servicio profesional que los acompaña por primera vez en la historia de la medicina moderna. El conocimiento que nos transmiten y la tranquilidad que nos dan con su consejo e indicaciones a través de la consulta a distancia merecen el respeto y la remuneración económica que siempre debió haber existido.
            </p>

            <p className="contenido_nosotros">
                Nuestra filosofía parte del agradecimiento y reconocimiento a su trabajo y a su esfuerzo, los profesionales de la salud son las personas que tienen una labor fundamental en el desarrollo de la humanidad, y hoy en 2020, el mundo se vuelve a dar cuenta de la importancia de su trabajo y del valor que aportan con sus conocimientos e investigaciones, en Medpacom lo sabemos desde siempre.
            </p>

            <p className="contenido_nosotros">
                Gracias por utilizar tu nueva herramienta, diseñada por ti y para ti.
            </p>

            <p className="contenido_nosotros">
                Siempre agradecido.
            </p>

            <p className="contenido_nosotros">
                <b>Oscar Luís De la Garza González / CEO / Fundador</b>
            </p>

            <Row>
                <div className="col-md-12 padding-leftright-null">
                    <div className="text pt-5 p-4 padding-md-bottom-null">
                        <i className="icon ion-ios-pulse service material left"></i>
                        <div className="service-content">
                            <h6 className="heading  margin-bottom-extrasmall">MISIÓN</h6>
                            <p className="contenido_nosotros margin-bottom-null">
                                Ofrecer el mejor sistema de comunicación entre los profesionales de la salud y sus pacientes, una herramienta de trabajo con la que puedan generar bienestar a sus pacientes e ingresos a través de la consulta profesional a distancia.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 padding-leftright-null">
                    <div className="text p-4 padding-md-bottom-null">
                        <i className="icon ion-ios-pulse service material left"></i>
                        <div className="service-content">
                            <h6 className="heading  margin-bottom-extrasmall">VISIÓN</h6>
                            <p className="contenido_nosotros margin-bottom-null">
                                Ser la red social profesional para las doctoras y doctores a nivel mundial, fortaleciendo el vínculo de confianza entre ellos y sus pacientes.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 padding-leftright-null">
                    <div className="text p-4 padding-md-bottom-null">
                        <i className="icon ion-ios-pulse service material left"></i>
                        <div className="service-content">
                            <h6 className="heading  margin-bottom-extrasmall">OBJETIVO</h6>
                            <p className="contenido_nosotros margin-bottom-null">
                                Estar al servicio de los profesionales de la salud 24/7 a nivel mundial, contar con los medios necesarios para ser la mejor herramienta de comunicación profesional que ayude a los mejores especialistas a generar valor, confianza, profesionalismo e ingresos a través de la consulta a distancia con los pacientes habituales que visitan su consultorio.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 padding-leftright-null">
                    <div className="text p-4 padding-md-bottom-null">
                        <i className="icon ion-ios-pulse service material left"></i>
                        <div className="service-content">
                            <h6 className="heading  margin-bottom-extrasmall">MANTRA</h6>
                            <p className="contenido_nosotros margin-bottom-null">
                                Servir a los profesionales de la salud.
                            </p>
                        </div>
                    </div>
                </div>
            </Row>

            <h5 className="pt-5">EQUIPO:</h5>

            <ul className="contenedor_lista_miembros">
            	<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Oscar Luis De la Garza González / Fundador / Director General</li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Oscar De la Garza Lozano / Fundador / Presidente del consejo médico de MEDPACOM y dirección de relaciones públicas. </li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Hernán Patricio González Segovia / Fundador / Director de Marketing</li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Miguel de Jesús Muñoz Perea / Fundador /  Director Financiero</li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Paola Denysse Reichardt Vera / Director de Operaciones e Innovación </li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> Javier Edgardo Núñez González / Director Jurídico.</li>
				<li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i> César Alfonso Leal González -  Solgeek  / Dirección de desarrollo tecnológico.</li>
			</ul>

            <h5 className="pt-5">PRIMER CONSEJO MÉDICO DE MEDPACOM</h5>
            
            <div id="showcase-projects" className="row mt-5">
                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/11.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Presidente</h6>
                            <p className="heading margin-null">Dr. Oscar De la Garza Lozano</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/8.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Vicepresidente</h6>
                            <p className="heading margin-null">Dr. Juan De Dios Maldonado Alvarado</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/7.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Secretario</h6>
                            <p className="heading margin-null">Dr. Jonatán De la Garza González</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/3.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Cuauhtémoc Celis González</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/13.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Roberto Hernández Niño</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/4.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Felipe Pérez Rada</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/18.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejera</h6>
                            <p className="heading margin-null">Dra. Dolores Vázquez Alemán</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/10.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Alberto Chapa Lobo</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/9.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Marco Tulio Flores Gutiérrez</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/1.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Arturo Ramírez Leal&nbsp;</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/2.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Carlos Gutiérrez Flores</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/19.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Marcos Ibarra Flores</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/17.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejera</h6>
                            <p className="heading margin-null">Dra. Cecilia Britton Robles</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/6.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Jaime Torres Gómez</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/14.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Víctor Batiza Reséndiz</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/15.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Víctor López Valeiro&nbsp;</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/12.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Ricardo de Hoyos Parra</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/16.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Wilfrido Nevárez Álvarez</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/0.png" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Carlos Díaz Olachea&nbsp;</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/20.jpg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Rómulo Ramírez Gutiérrez</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/consejo/21.jpeg" alt="" />
                        <div className="content">
                            <h6 className="heading black">Consejero</h6>
                            <p className="heading margin-null">Dr. Gilberto Montiel Amoroso</p>
                        </div>
                    </div>
                </div>
            </div>

            <h5 className="pt-5">REPRESENTANTES DE MÉXICO</h5>
            
            <div id="showcase-projects" className="row mt-5">
                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/representantes/1.jpeg" alt="" />
                        <div className="content">
                            <h6 className="heading black">MÉXICO | CDMX</h6>
                            <p className="heading margin-null">Dr. Marcos A. Rodríguez Perales</p>
                        </div>
                    </div>
                </div>

                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/representantes/3.jpeg" alt="" />
                        <div className="content">
                            <h6 className="heading black">MÉXICO | REYNOSA</h6>
                            <p className="heading margin-null">Dr. Marco Antonio Bracho Ugarte</p>
                        </div>
                    </div>
                </div>
            </div>

            <h5 className="pt-5">REPRESENTANTES DE MEDPACOM EN EL MUNDO</h5>
            
            <div id="showcase-projects" className="row mt-5">
                <div className="item col-md-3 mt-3">
                    <div className="showcase-project">
                        <img src="assets/images/representantes/2.jpeg" alt="" />
                        <div className="content">
                            <h6 className="heading black">ESPAÑA | PRESIDENTE DEL CONSEJO MEDICO DE MEDPACOM UE</h6>
                            <p className="heading margin-null">Dr. José Luis Neyro Bilbao</p>
                        </div>
                    </div>
                </div>
            </div>

            <h5 className="pt-5">ESTOS SON LOS ASPECTOS QUE NOS HACEN DIFERENTES A LAS DEMÁS PLATAFORMAS DIGITALES:</h5>

            <p className="contenido_nosotros">
                Somos la primera plataforma digital de salud y comunicación en el mundo con un Consejo Médico formado por presidentes, expresidentes, y vicepresidentes de consejos nacionales e internacionales, directores y exdirectores de hospitales privados y públicos, líderes en investigaciones médicas y extraordinarios seres humanos.
            </p>

            <ul className="contenedor_lista_miembros">
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Nivel de seguridad con respaldo de Amazon AWS.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Servicio sin costo mensual o anual a los profesionales de la salud.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Sin límite de consultas a distancia.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>90% de los ingresos son para los especialistas.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Consultas simultaneas ilimitadas.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Usuarios certificados por CONACEM.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Soporte técnico 24/7.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>No es un directorio médico.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>No se califica a los doctores y doctoras.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>La comunicación es con sus pacientes actuales y futuros.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>La consulta es prepagada al costo que el doctor decida.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>La herramienta está diseñada para antes, durante y después de la consulta física.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Comunicación entre colegas privada y segura.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>El profesional tiene el control de su tiempo e ingresos.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Privacidad absoluta, solo los doctores pueden aceptar nuevos pacientes.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Contamos con el respaldo de un Consejo Médico de especialistas.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>El Consejo médico Medpacom toma las decisiones con respecto al uso de la herramienta.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Somos la primera herramienta de comunicación diseñada por doctores y doctoras para los profesionales de la salud.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Invita a tus pacientes a interactuar en tu medio de comunicación y genera honorarios.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Nosotros admiramos, valoramos y respetamos a las doctoras y doctores.</li>
            </ul>

            <h5 className="pt-5">OTROS DESARROLLOS DIGITALES:</h5>

            <ul className="contenedor_lista_miembros">
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Sistemas de alta vulnerabilidad de datos sensibles.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Cobro de hasta el 30% de los ingresos en consultas a distancia y física en algunos casos.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Renta mensual o anual para uso de la plataforma.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Redes sociales no especializadas en salud.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Límite de consultas, depende del paquete contratado.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Tarifas variables controlas por las plataformas.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Cualquier persona puede hacerse pasar por un especialista en algunos casos.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Son directorios médicos y se paga por salir en los primeros lugares.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Los pacientes califican al doctor.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Se vende publicidad dentro de las plataformas.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Los datos privados de los usuarios se comercializan.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Los desarrollos tecnológicos ven a los profesionales de la salud como choferes de Uber.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Cualquier médico puede acceder a la red y consultar, aunque no sea especialista en el tema.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Call centers de médicos cirujanos parteros, al final recomiendan ver a un especialista.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>El doctor consulta antes de hacer un cobro.</li>
                <li className="lista_miembros py-1"> <i className="fa fa-check mr-1"></i>Sistemas genéricos no especializados de comunicación (cualquiera pude hacer una cuenta de especialista).</li>
            </ul>

            <p className="contenido_nosotros">
                Nosotros somos MEDPACOM, la primera herramienta profesional de comunicación al servicio de los profesionales de la salud.
            </p>

            <p className="contenido_nosotros">
                <b>Oscar Luís De la Garza González / CEO / Fundador</b>
            </p>

            <p className="contenido_nosotros mb-5 pb-5">
                Todos los derechos reservados, Medpacom S.A.P.I. de C.V. { new Date().getFullYear() }
            </p>

        </Container>
    )
}

export default About;