import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

const Home = () => {
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h1 className="py-3">Aviso de Privacidad</h1>
                </Col>
                <Col xs={12}>
                    <p className="contenido_nosotros">
                        Aviso de Privacidad de Datos Personales para MEDPACOM, S.A.P.I. de C.V. (en lo sucesivo "MEDPACOM") con domicilio en Av. Lázaro Cárdenas 2224, Zona Loma Larga Oriente en San Pedro Garza García, Nuevo León, México.
                    </p>
                    <p className="contenido_nosotros">
                        En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de Particulares publicada en el Diario Oficial de la Federación el 05 de julio de 2010 (en lo sucesivo la "Ley"), le informamos que MEDPACOM será el responsable de recabar, velar, utilizar, almacenar, proteger y/o transferir sus datos personales.
                    </p>
                    <h5>1. Datos personales que se recaban</h5>
                    <p className="contenido_nosotros">MEDPACOM podrá recabar y, en su caso, tratar de manera enunciativa más no limitativa los siguientes datos personales, dependiendo de la relación que con usted exista:</p>
                    <ul>
                        <li>Nombre</li>
                        <li>Edad</li>
                        <li>Género</li>
                        <li>Domicilo</li>
                        <li>Fecha de Nacimiento</li>
                        <li>Estado Civil</li>
                        <li>Ocupación</li>
                        <li>Correo electrónico</li>
                        <li>Teléfono</li>
                        <li>Datos familiares</li>
                        <li>Escolaridad</li>
                        <li>Número de afiliación al Instituto Mexicano del Seguro Social</li>
                        <li>Registro Federal de Contribuyentes</li>
                        <li>Datos bancarios, financieros y/o patrimoniales</li>
                        <li>Fotografía o imagen de perfil</li>
                    </ul>
                    <h5>2. Finalidades del tratamiento de los datos personales</h5>
                    <p className="contenido_nosotros">
                        MEDPACOM recaba, utiliza, almacena o transfiere sus datos personales, en la medida en que las leyes aplicables lo permiten, para llevar a cabo de manera enunciativa más no limitativa lo siguiente:
                    </p>
                    <ul>
                        <li>Ofrecer bienes y servicios.</li>
                        <li>Determinar la satisfacción, perfil y las necesidades de sus clientes.</li>
                        <li>Conocer la participación de MEDPACOM en el mercado.</li>
                        <li>Desarrollar nuevos productos y servicios.</li>
                        <li>Conformar expedientes de clientes.</li>
                        <li>Distribuir comunicaciones y promociones.</li>
                        <li>Analizar su información curricular para posibles oportunidades laborales.</li>
                        <li>Cumplir con las disposiciones legales aplicables.</li>
                        <li>Generar un sentido de identidad dentro de la plataforma.</li>
                    </ul>
                    <p className="contenido_nosotros">
                        En caso de que usted no esté de acuerdo con alguna o varias de las finalidades anteriormente descritas, le pedimos hacernos saber dicha negativa a la dirección de correo electrónico siguiente: protecciondedatos@MEDPACOM.com, al domicilio de la Sociedad.
                    </p>
                    <p className="contenido_nosotros">
                        Así mismo le informamos que sus datos personales serán conservados y tratados únicamente por el tiempo necesario, a fin de cumplir con las finalidades descritas y/o de conformidad con lo que establezcan las disposiciones legales aplicables.
                    </p>
                    <p className="contenido_nosotros">
                        El uso de una imagen o fotografía de perfil es unicamente para identificación y personalización del perfil dentro de la aplicación respecto a otros usuarios. No es obligatorio el hacerlo ni necesario para el correcto funcionamiento de la misma.
                    </p>
                    <h5>3. Seguridad de los datos personales</h5>
                    <p className="contenido_nosotros">
                        MEDPACOM implementará las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado. Únicamente el personal autorizado, que ha cumplido y observado los correspondientes requisitos de confidencialidad, podrá participar en el tratamiento de sus datos personales. El personal autorizado tiene prohibido permitir el acceso de personas no autorizadas y utilizar sus datos personales para fines distintos a los establecidos en el presente Aviso de Privacidad.
                    </p>
                    <h5>4. Comunicaciones y Transferencias de datos personales</h5>
                    <p className="contenido_nosotros">
                        Como parte de las operaciones empresariales llevadas a cabo por MEDPACOM, ésta podrá transferir a terceros, nacionales o extranjeros, algunos o todos sus datos personales. Dichos terceros podrán ser asesores profesionales externos y/u otros prestadores de servicios. En caso de que usted no desee que MEDPACOM lleve a cabo la transferencia referida en el presente párrafo le pedimos dirigir dicha solicitud a la dirección de correo electrónico siguiente: protecciondedatos@MEDPACOM.com, al domicilio de la Sociedad.
                    </p>
                    <p className="contenido_nosotros">
                        MEDPACOM se asegurará a través de la firma de convenios y/o la adopción de otros documentos vinculantes, que dichos terceros mantengan medidas de seguridad, administrativas, técnicas y físicas adecuadas para resguardar sus datos personales, así como que dichos terceros únicamente utilicen sus datos personales para las finalidades para las cuales fueron contratados y de conformidad con el presente Aviso de Privacidad.
                    </p>
                    <h5>5. Derechos que corresponden a los titulares de Datos Personales</h5>

                    <p className="contenido_nosotros">Usted, como titular de datos personales, podrá ejercitar los derechos de acceso, rectificación, cancelación y oposición (conjuntamente los "Derechos ARCO") respecto al tratamiento de sus datos personales. En virtud de lo anterior, si desea cualquier forma de acceso, rectificación, cancelación y/u oposición respecto de los datos personales con los que cuenta MEDPACOM, lo podrá hacer a través de la cuenta de correo: protecciondedatos@MEDPACOM.com, al domicilio de la Sociedad.</p>
                    <p className="contenido_nosotros">De conformidad con lo establecido en la Ley, la solicitud para ejercer cualquiera de los Derechos ARCO deberá contener:</p>
                    <p className="contenido_nosotros">Nombre completo y domicilio del titular de los Datos Personales, u otro medio para comunicarle la respuesta a su solicitud.</p>
                    <p className="contenido_nosotros">Documentos que acrediten la identidad o la representación legal del titular de los Datos Personales.</p>
                    <p className="contenido_nosotros">Descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados.</p>
                    <p className="contenido_nosotros">Cualquier otro elemento o documento que facilite la localización de los Datos Personales.</p>
                    <p className="contenido_nosotros">En el caso de solicitudes de rectificación de Datos Personales, usted deberá indicar las modificaciones a realizarse y/o aportar la documentación que sustente su petición.</p>
                    <p className="contenido_nosotros">Los demás que determine la legislación aplicable.</p>
                    <p className="contenido_nosotros">Así mismo le informamos que, sin perjuicio del ejercicio de cualquiera de los Derechos ARCO, usted podrá revocar, en todo momento, el consentimiento que haya otorgado y que fuere necesario para el tratamiento de sus datos personales mediante solicitud enviada por medio de la cuenta de correo electrónico siguiente: protecciondedatos@MEDPACOM.com, al domicilio de la Sociedad.</p>
                    <h5>6. Cambios al Aviso de Privacidad</h5>
                    <p className="contenido_nosotros mb-5 pb-5">
                        MEDPACOM se reserva el derecho de efectuar, en cualquier momento, modificaciones o actualizaciones al presente aviso de privacidad con el objetivo de incorporar reformas legislativas u opiniones jurisprudenciales, políticas internas, prácticas de mercado, así como requerimientos para la prestación u ofrecimiento de nuestros bienes y servicios. Dichas modificaciones estarán disponibles a través de la página de Internet: https://www.MEDPACOM.com o mediante los medios contemplados en la Ley.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Home;