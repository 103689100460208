import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


const Inicio = () => {

    const user = useSelector(state => state.auth.user);

    const styles = {
        colorPrimary: {
            color: '#42B4D9'
        },
        subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
        info: { margin: 0, marginBottom: 10 },
    }

    return (
        user ? <div className="py-5 container-fluid">
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col-md-4 pt-4">
                            <h3>{user.nombre} {user.apellido}</h3>
                            <h6 className="text-muted">En Medpacom desde {`${new Date(user.date).toLocaleDateString(
                                'es-MX',
                                { year: 'numeric', month: 'long', day: 'numeric' }
                            )}`}</h6>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md">
                                    <h3>{user.numero_medicos}</h3>
                                    <p>medicos agregados</p>
                                </div>
                                <div className="col-md">
                                    <h3>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(user.ganacia_total)}</h3>
                                    <p>ganancias totales</p>
                                </div>
                                <div className="col-md">
                                    <h3>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(user.ganacia_mes)}</h3>
                                    <p>ganancias del mes</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mt-3 row">
                                    <div className="col-md-12">
                                        <h4 style={styles.colorPrimary}>Información Personal</h4>
                                    </div>
                                    <div className="col-md-12">
                                        <p style={styles.subtitulo}>Nombre</p>
                                        <p style={styles.info}>{user.nombre} {user.apellido}</p>
                                        <p style={styles.subtitulo}>Correo</p>
                                        <p style={styles.info}>{user.email}</p>
                                        <p style={styles.subtitulo}>Celular</p>
                                        <p style={styles.info}>{user.celular}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 row">
                                    <div className="col-md-12">
                                        <h4 style={styles.colorPrimary}>Información Bancaria</h4>
                                    </div>
                                    <div className="col-md-12">
                                        <p style={styles.subtitulo}>Titular</p>
                                        <p style={styles.info}>{user.cuenta?.titular}</p>
                                        <p style={styles.subtitulo}>No. de cuenta</p>
                                        <p style={styles.info}>{user.cuenta?.num_cuenta}</p>
                                        <p style={styles.subtitulo}>CLABE</p>
                                        <p style={styles.info}>{user.cuenta?.clabe}</p>
                                        <p style={styles.subtitulo}>Banco</p>
                                        <p style={styles.info}>{user.cuenta?.banco}</p>
                                        <p style={styles.subtitulo}>RFC</p>
                                        <p style={styles.info}>{user.cuenta?.rfc}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : <div></div>
    )
}

export default Inicio;