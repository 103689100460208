import React, { useState } from 'react';
import { register } from '../../Redux/actions/auth'
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom'


const styles = {
    rowStyle: {
        height: '80vh',
    },
    bgImg: {
        height: '40vw',
        opacity: 0.05,
        position: 'absolute',
        left: '-220px',
        top: '0px',
    },
    btnIniciar: {
        background: '#42B4D9',
        borderColor: '#42B4D9'
    }    
}

const Register = ({ }) => {

    let location = useLocation();

    const dispatch = useDispatch();
    const [registerData, setRegisterData] = useState({
        nombre: '',
        apellido: '',
        celular: '',
        email: '',
        password: ''
    });

    const { isAuthenticated, type } = useSelector(state => state.auth)
    // Redireccionamos si está autenticado
    if (isAuthenticated && type === 1) return <Redirect push to="/dashboard_medico" />;
    if (isAuthenticated && type === 2) return <Redirect push to="/dashboard_paciente" />;
    if (isAuthenticated && type === 3) return <Redirect push to="/dashboard_colaborador" />;

    const { nombre, apellido, celular, email, password } = registerData;
    const onChange = (e) => {
        setRegisterData({
            ...registerData, [e.target.name]: e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        dispatch(register({ nombre, apellido, celular, email, password }));
    }


    return (
        <div className="row d-flex align-items-center" style={styles.rowStyle}>
            <img src="assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-6 offset-md-3">
                <div className="card bg-light ">
                    <div className="card-body">
                        <div className="text-center mb-5">
                            <img src="assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" height="45" />
                            <h4 className="card-title">¡Bienvenido a Medpacom!</h4>
                            <p className="text-muted">Estás registrandote como colaborador, si ya tienes una cuenta 
                                <span>
                                    <a href="/login_colaborador"> inicia sesión aquí</a>
                                </span>
                            </p>   
                        </div>
                        <form onSubmit={submitForm} method="POST">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre(s)"
                                    name="nombre"
                                    value={nombre}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Apellido(s)"
                                    name="apellido"
                                    value={apellido}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Celular"
                                    name="celular"
                                    value={celular}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Correo o nombre de usuario"
                                    name="email"
                                    value={email}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Contraseña"
                                    name="password"
                                    value={password}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group text-right pt-3">
                                <button type="submit" className="btn btn-primary" style={styles.btnIniciar}>Crear cuenta</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>)
}

export default Register;