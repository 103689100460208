import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../Redux/actions/auth'

const Header = () => {

    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout())
    }
    return (
        <header id="header" className="fixed transparent">
            <nav className="navbar navbar-default white">
                <div id="logo">
                    <a className="navbar-brand" href="/home">
                        <img src="/assets/images/logo_azul.png" className="normal" alt="logo" />
                        <img src="/assets/images/logo_azul.png" className="retina" alt="logo" />
                        <img src="/assets/images/logo_blanco.png" className="normal white-logo" alt="logo" />
                        <img src="/assets/images/logo_blanco.png" className="retina white-logo" alt="logo" />
                    </a>
                </div>
            </nav>
        </header>
    )
}

export default Header;