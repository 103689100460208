import React from 'react'

const Loader = () => {
    return (
        <div id="myloader">
            <span className="loader">
                <img src="/assets/images/logo_azul.png" className="normal" alt="logo" />
                <img src="/assets/images/logo_azul.png" className="retina" alt="logo" />
            </span>
        </div>
    )
}

export default Loader;