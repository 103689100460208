import React, { useState, Fragment } from 'react';
import Facturas from '../../Components/DashboardMedico/Facturas';
import Inicio from '../../Components/DashboardMedico/Inicio';
import HeaderMedico from '../../Components/Layout/HeaderMedico';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const DashboardMedico = () => {

    const [component, setComponent] = useState(<Facturas />)

    const { isAuthenticated, loading, type } = useSelector(state => state.auth)
    // Redireccionamos si está autenticado
    if (isAuthenticated && type === 2) return <Redirect push to="/dashboard_paciente" />;
    if (isAuthenticated && type === 3) return <Redirect push to="/dashboard_colaborador" />;

    return (
        <Fragment>
            <HeaderMedico setComponent={setComponent} component={component} />
            {component}
        </Fragment>
    )
}

export default DashboardMedico;