import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'

const TutorialMedico = () => {    
    return (
    	<Fragment>
			<div className="headermed py-5">
			    <div className="col-md-5 offset-md-1 col-12 verticalalign textosheader">
			        <h1>TUTORIALES</h1>
			        <p>En esta sección podras encontrar video tutoriales que te guiaran en el proceso de uso de tu aplicación.</p>
			    </div>
				<div className="col-md-4 col-md-offset-1 hiddensm"></div>
			</div>

			<div id="home-wrap" className="content-section py-3">
				<div className="container-fluid">
			        <div className="row conocenos">
			            <div className="col-md-12 col-sm-12 verticalalign">
		                    <h3 className="margin-bottom-small titulo text-center">1.- DESCARGA Y REGISTRO</h3>
			            </div>
			        	<div className="offset-md-3 col-md-6 col-12 text-center">
			        		<div className="video-responsive">
				        		<iframe src="https://www.youtube.com/embed/fQwGXhK6DiU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				        	</div>
			        	</div>
		            </div>

		            <div className="row conocenos mt-3">
			            <div className="col-md-12 col-sm-12 verticalalign">
		                    <h3 className="margin-bottom-small titulo text-center">2.- COMPLETAR PERFIL</h3>
			            </div>
			        	<div className="offset-md-3 col-md-6 col-12 text-center">
			        		<div className="video-responsive">
				        		<iframe src="https://www.youtube.com/embed/SnxaoXXoVTI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				        	</div>
			        	</div>
		            </div>

		            <div className="row conocenos mt-3">
			            <div className="col-md-12 col-sm-12 verticalalign">
		                    <h3 className="margin-bottom-small titulo text-center">3.- INVITAR A TUS PACIENTES</h3>
			            </div>
			        	<div className="offset-md-3 col-md-6 col-12 text-center">
			        		<div className="video-responsive">
				        		<iframe src="https://www.youtube.com/embed/GCEhDa0DwQI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				        	</div>
			        	</div>
		            </div>

		            <div className="row conocenos mt-3">
			            <div className="col-md-12 col-sm-12 verticalalign">
		                    <h3 className="margin-bottom-small titulo text-center">4.- NOTIFICACIONES</h3>
			            </div>
			        	<div className="offset-md-3 col-md-6 col-12 text-center">
			        		<div className="video-responsive">
				        		<iframe src="https://www.youtube.com/embed/WUP1r0FSTJk" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				        	</div>
			        	</div>
		            </div>

		            <div className="row conocenos mt-3">
			            <div className="col-md-12 col-sm-12 verticalalign">
		                    <h3 className="margin-bottom-small titulo text-center">5.- CONSULTAS</h3>
			            </div>
			        	<div className="offset-md-3 col-md-6 col-12 text-center">
			        		<div className="video-responsive">
				        		<iframe src="https://www.youtube.com/embed/nDmytJS2Z4A" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				        	</div>
			        	</div>
		            </div>

		            <div className="row conocenos mt-3">
			            <div className="col-md-12 col-sm-12 verticalalign">
		                    <h3 className="margin-bottom-small titulo text-center">6.- FINALIZAR CONSULTAS</h3>
			            </div>
			        	<div className="offset-md-3 col-md-6 col-12 text-center">
			        		<div className="video-responsive">
				        		<iframe src="https://www.youtube.com/embed/m6BRp_XCRO8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				        	</div>
			        	</div>
		            </div>
			    </div>
		    </div>
	    </Fragment>
    )
}

export default TutorialMedico;