import { LOAD_PERFIL, EDITAR_PERFIL } from '../actions/types'

const initialState = {};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOAD_PERFIL:
            return {
                ...state,
                perfil: payload.perfil,
                bancos: payload.bancos
            }
        case EDITAR_PERFIL:
            return {
                ...state,
                perfil: payload.perfil
            }
        default:
            return state;
    }

}