import { LOAD_FACTURAS, PAGAR_FACTURAS } from '../actions/types'

const initialState = {};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOAD_FACTURAS:
            return {
                ...state, ...payload
            }
        case PAGAR_FACTURAS:
            return {
                ...state,
                cobros_facturados: payload.cobros_facturados,
                cobros_pendientes: payload.cobros_pendientes
            }
        default:
            return state;
    }

}