import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Home = () => {

    const { isAuthenticated, loading, type } = useSelector(state => state.auth)
    
    if (isAuthenticated && type === 1) return <Redirect push to="/dashboard_medico" />;
    if (isAuthenticated && type === 2) return <Redirect push to="/dashboard_paciente" />;
    
    return (
    	<Fragment>
			<div id="slider-video" className="fullpage-wrap">
			    <div className="local-video" style={{ backgroundImage: 'url(assets/images/portada.jpg)' }}>
			        <div className="text">
			            <h1 className="big title white center margin-bottom" style={{ fontFamily: 'Nevis' }}>MEDPACOM</h1>
			            <h2 id="susbtitulo_principal" className="small white uppercase" style={{ fontFamily: 'Nevis', lineHeight: '30px', fontSize: '18px' }}>La primera aplicacion que te permite profesionalizar el servicio de la comunicacion a distancia con tus pacientes habituales y futuros, bienvenido a la evolucion de la comunicacion con los profesionales de la salud</h2>
			        </div>
			        <div className="gradient"></div>
			    </div>
			</div>

			<div id="home-wrap" className="content-section">
                <div className="container-fluid">
			        <div className="row">
		                <div className="col-md-6 especializados servicios distint_app">
		                    <div id="descripcion-especializados" className="col-xs-12 col-md-12 descripcion">
		                        <div style={{ width: '100%' }} className="text-center">
		                            <i className="fa fa-user-md icon-especializados"></i>
		                        </div>
		                        <h3 className="titulo text-center pb-20" style={{ marginTop: '50px' }}>¿ERES MEDICO?</h3>
		                        <div className="row botones-hidden-especializados text-center">
			                		<div className="col-md-12 col-sm-12 text-center">
			                			<a href="/medico" className="btn-alt medium active margin-null">Da click aquí</a>
		                			</div>
	                			</div>
		                    </div>
		                </div>
		                <div className="col-md-6 medida servicios distint_app">
		                    <div id="descripcion-medida"  className="col-xs-12 col-md-11 descripcion">
		                        <div style={{ width: '100%' }} className="text-center">
		                            <i className="fa fa-users icon-medida"></i>
		                        </div>
		                        <h3 className="titulo text-center pb-20" style={{ marginTop: '50px' }}>¿ERES PACIENTE?</h3>
		                        <div className="row botones-hidden-medida text-center">
			                		<div className="col-md-12 col-sm-12 text-center">
			                			<a href="/paciente" className="btn-alt medium white margin-null">Da click aquí</a>
		                			</div>
	                			</div>
		                    </div>
		                </div>
			        </div>
			    </div>
		    </div>
	    </Fragment>
    )
}

export default Home;