import React, { useState, Fragment } from 'react';
import Inicio from '../../Components/DashboardPaciente/Inicio';
import HeaderPaciente from '../../Components/Layout/HeaderPaciente';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const DashboardPaciente = () => {

    const [component, setComponent] = useState(<Inicio />)
    
    const { isAuthenticated, loading, type } = useSelector(state => state.auth)
    // Redireccionamos si está autenticado
    if (isAuthenticated && type === 1) return <Redirect push to="/dashboard_medico" />;
    if (isAuthenticated && type === 3) return <Redirect push to="/dashboard_colaborador" />;

    return (
        <Fragment>
            <HeaderPaciente setComponent={setComponent} component={component} />
            {component}
        </Fragment>
    )
}

export default DashboardPaciente;