import React, { useEffect, useState, createRef } from 'react';

import { isMobile } from 'is-mobile'
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import { loadFacturas, subirFactura } from '../../Redux/actions/facturas'
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../Redux/actions/alert';

const styles = {
    ganaciaRow: {
        background: '#42B4D9'
    }
}

const Facturas = () => {

    const facturas = useSelector(state => state.facturas);

    let facturaPdf = createRef();
    let facturaXml = createRef();

    const onChangeFacturaPdf = () => {
        setArchivos({
            ...archivos, pdf: facturaPdf.current.files[0]
        })
    }
    const onChangeFacturaXml = () => {
        setArchivos({
            ...archivos, xml: facturaXml.current.files[0]
        })
    }

    useEffect(() => {
        load();
    }, []);

    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const load = () => {
        dispatch(loadFacturas());
    }

    const handleSubirFactura = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        if (!archivos.pdf || !archivos.xml) {
            dispatch(setAlert('Selecciona los archivos correctamente', 'danger'));
        } else if (archivos.pdf && archivos.xml) {
            formData.append('id_cobro', pagoSeleccionado);
            formData.append('pdf', archivos.pdf);
            formData.append('xml', archivos.xml);
            dispatch(subirFactura(formData));
            setArchivos({
                pdf: undefined,
                xml: undefined
            });
        }
    }

    const [pagoSeleccionado, setPagoSeleccionado] = useState(undefined);
    const [archivos, setArchivos] = useState({
        pdf: undefined,
        xml: undefined
    })
    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };
    const conditionalRowStyles = [
        {
            when: row => row._id === pagoSeleccionado,
            style: {
                backgroundColor: '#f8f9fa !important',
            },
        }
    ];

    const columns = [
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: true,
            cell: item => `${new Intl.DateTimeFormat("es-MX").format(new Date(item.fecha))}`,
        }, {
            name: 'Total a facturar',
            selector: 'total_facturar',
            sortable: true,
            cell: item => `${new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(item.cantidad_factura)}`,
        }, {
            name: 'Total a cobrar',
            selector: 'total_cobrar',
            sortable: true,
            cell: item => `${new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(item.cantidad)}`,
        }, {
            name: '',
            selector: '',
            sortable: true,
            cell: item => <button className="btn btn-link text-primary" onClick={() => {
                !isMobile() ? setPagoSeleccionado(item._id) : setShowModal(true)
            }}> Subir factura </button>,
        }
    ];


    const columnsFacturados = [
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: true,
            cell: item => `${new Intl.DateTimeFormat("es-MX").format(new Date(item.fecha))}`,
        }, {
            name: 'Total a cobrar',
            selector: 'total_cobrar',
            sortable: true,
            cell: item => `${new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(item.cantidad)}`,
        }, {
            name: 'Pagado',
            selector: 'pagado',
            sortable: true,
            cell: item => item.pagado ? 'Si' : 'No',
        },
    ];

    return (
        <div className="py-5 container-fluid">

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Subir factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-muted">
                        En este espacio puedes subir las facturas para el pago seleccionado
                    </p>
                    <form>
                        <div className="form-group">
                            <label className="col-md-12" htmlFor="facturaPdf">PDF</label>
                            <input className="col-md-12" name="facturaPdf" id="facturaPDf" type="file" ref={facturaPdf} onChange={onChangeFacturaPdf} accept="application/pdf" />
                        </div>
                        <div className="form-group">
                            <label className="col-md-12" htmlFor="facturaXml">XML</label>
                            <input className="col-md-12" name="facturaXml" id="facturaXml" type="file" ref={facturaXml} onChange={onChangeFacturaXml} accept="application/xml" />
                        </div>
                        <div>
                            <button className="btn btn-primary mt-3" onClick={handleSubirFactura}>Guardar</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <div className="row">
                <div className={pagoSeleccionado ? 'col-md-7 offset-md-1' : 'col-md-10 offset-md-1'}>
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Facturas</h2>
                            <p className="text-muted">En este espacio podras subir las facturas necesarias para el periodo en curso.</p>
                        </div>
                    </div>
                    <div className="row">
                        <hr className="col-md-12" />
                    </div>
                    <div className="row py-3">
                        <div className="col-md my-2">
                            <div className="card py-3 px-3 bg-light">
                                <h4>
                                    {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(facturas.honorarios)}
                                </h4>
                                <h6>Honorarios</h6>
                            </div>
                        </div>
                        <div className="col-md my-2">
                            <div className="card py-3 px-3 bg-light">
                                <h4>
                                    {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(facturas.comision)}
                                </h4>
                                <h6>Comisión</h6>
                            </div>
                        </div>
                        <div className="col-md my-2">
                            <div className="card py-3 px-3 bg-light">
                                <h4>
                                    {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(facturas.total_factura)}
                                </h4>
                                <h6>Total factura</h6>
                            </div>
                        </div>
                        <div className="col-md my-2">
                            <div className="card py-3 px-3 bg-light">
                                <h4>
                                    {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(facturas.impuesto_isr)}
                                </h4>
                                <h6>ISR</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row my-4" style={styles.ganaciaRow}>
                        <div className="col-md-12 text-center py-3 ">
                            <h6 className="text-white font-weight-bold">Ganancia Actual</h6>
                            <h4 className="text-white font-weight-bold">
                                {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(facturas.total)}
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <hr className="col-md-12" />
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-body">
                                <h5 className="card-title">Cobros por facturar</h5>
                                <h6 className="card-subtitle mb-2 text-muted">Para que se procese tu pago es necesario que adjuntes la factura de tus cobros</h6>
                                <DataTable
                                    columns={columns}
                                    data={facturas.cobros_pendientes}
                                    defaultSortField="title"
                                    noHeader={true}
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    conditionalRowStyles={conditionalRowStyles}
                                />

                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card-body">
                                <h5 className="card-title">Cobros facturados</h5>
                                <DataTable
                                    columns={columnsFacturados}
                                    data={facturas.cobros_facturados}
                                    defaultSortField="title"
                                    noHeader={true}
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    conditionalRowStyles={conditionalRowStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={pagoSeleccionado ? 'col-md-3 bg-light' : 'd-none'}>
                    <div className="col-md-12 text-right">
                        <button className="btn pt-4" onClick={() => setPagoSeleccionado(undefined)}><i className="fa fa-times"></i></button>
                        <hr></hr>
                    </div>
                    <div className="col-md-12 pt-4">
                        <p className="text-muted">
                            En este espacio puedes subir las facturas para el pago seleccionado
                        </p>
                        <form>
                            <div className="form-group">
                                <label className="col-md-12" htmlFor="facturaPdf">PDF</label>
                                <input className="col-md-12" name="facturaPdf" id="facturaPDf" type="file" ref={facturaPdf} onChange={onChangeFacturaPdf} accept="application/pdf" />
                            </div>
                            <div className="form-group">
                                <label className="col-md-12" htmlFor="facturaXml">XML</label>
                                <input className="col-md-12" name="facturaXml" id="facturaXml" type="file" ref={facturaXml} onChange={onChangeFacturaXml} accept="application/xml" />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary mt-3" onClick={handleSubirFactura}>Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Facturas;