import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


const Inicio = () => {

    const user = useSelector(state => state.auth.user);

    const styles = {
        imgContainer: {
            width: 200, height: 200,
            borderRadius: '100px',
            backgroundImage: user ? `url(${user.url_perfil})` : '#AEAEAE',
            backgroundSize: 'cover',
        },
        colorPrimary: {
            color: '#42B4D9'
        },
        subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
        info: { margin: 0, marginBottom: 10 },
    }

    return (
        user ? <div className="py-5 container-fluid">
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col-md-12 my-4" style={styles.imgContainer}></div>
                            <h3>{user.nombre} {user.apellido}</h3>
                            <h6 className="text-muted">En Medpacom desde {`${new Date(user.date).toLocaleDateString(
                                'es-MX',
                                { year: 'numeric', month: 'long', day: 'numeric' }
                            )}`}</h6>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md">
                                    <h3>{user.numero_consultas}</h3>
                                    <p>consultas realizadas</p>
                                </div>
                                <div className="col-md">
                                    <h3>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(user.medpacash)}</h3>
                                    <p>medpacash</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <h4 style={styles.colorPrimary}>Información Personal</h4>
                                </div>
                                <div className="col-md-12">
                                    <p style={styles.subtitulo}>Nombre</p>
                                    <p style={styles.info}>{user.nombre} {user.apellido}</p>
                                    <p style={styles.subtitulo}>Fecha de nacimiento</p>
                                    <p style={styles.info}>{new Date(user.nacimiento).toLocaleDateString('es-MX')}</p>
                                    <p style={styles.subtitulo}>Correo</p>
                                    <p style={styles.info}>{user.email}</p>
                                    <p style={styles.subtitulo}>Celular</p>
                                    <p style={styles.info}>{user.celular}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : <div></div>
    )
}

export default Inicio;