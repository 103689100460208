import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const HomePaciente = () => {

    const { isAuthenticated, loading, type } = useSelector(state => state.auth)
    
    if (isAuthenticated && type === 1) return <Redirect push to="/dashboard_medico" />;
    if (isAuthenticated && type === 2) return <Redirect push to="/dashboard_paciente" />;
    
    return (
    	<Fragment>
			<div id="slider-video" className="fullpage-wrap">
			    <div className="local-video" style={{ backgroundImage: 'url(assets/images/portada.jpg)' }}>
			        <div className="text">
			            <h1 className="big title white center margin-bottom" style={{ fontFamily: 'Nevis' }}>MEDPACOM</h1>
			            <h2 id="susbtitulo_principal" className="small white uppercase" style={{ fontFamily: 'Nevis', lineHeight: '30px', fontSize: '18px' }}>La primera aplicacion que te permite profesionalizar el servicio de la comunicacion a distancia con tus pacientes habituales y futuros, bienvenido a la evolucion de la comunicacion con los profesionales de la salud</h2>
			        </div>
			        <div className="gradient"></div>
			    </div>
			</div>

			<div id="home-wrap" className="content-section">
				<div className="row padding-md margin-leftright-null dark-background">
                    <div className="col-md-12 text-center">
                        <h4 className="big margin-bottom-small white">Bienvenido a la sección exclusiva para pacientes, aquí podrás descargar la aplicación y ver los tutoriales para facilitar el uso de la plataforma.</h4>
                    </div>
                </div>

                <div className="container-fluid">
			        <div className="row conocenos py-4">
			            <div className="col-md-12 col-sm-12 verticalalign">
		                    <h3 className="margin-bottom-small titulo text-center">ESTO ES MEDPACOM</h3>
			            </div>
			        	<div className="offset-md-3 col-md-6 col-12 text-center">
			        		<div className="video-responsive">
			        			<iframe src="https://www.youtube.com/embed/9tndS3B__3E" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				        	</div>
			        	</div>
		            </div>
			    </div>

                <div className="container-fluid">
			        <div className="row grey-background conocenos py-5">
			            <div className="col-md-6 col-sm-12 verticalalign px-5">
		                    <h3 className="margin-bottom-small titulo">CONSULTA A DISTANCIA</h3>
		                    <p className="text-justify" style={{ paddingTop: '15px' }}>En Medpacom creamos un canal de comunicación para que estés cerca de tu médico de confianza a pesar de la distancia, recuerda que su tiempo y su conocimiento nos brindan la tranquilidad y el bienestar que nuestra vida necesita, checa sus horarios de consulta, sus costos y genera una consulta por el medio de tu preferencia ya sea chat, llamada o video llamada, completa tu expediente médico y agrega a los familiares que no pueden utilizar el sistema ya sea por su edad o alguna dificultad que no les permita realizar una consulta por ellos mismos, tus doctores están listos para iniciar una conversación.</p>
			            </div>
			        	<div className="col-md-6 col-sm-12 hiddensm" style={{ backgroundImage: 'url(assets/images/consulta_distancia.png)', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '300px' }}></div>
		            </div>
			    </div>

			    <div className="container-fluid">
			        <div className="row conocenos py-5">
			        	<div className="col-md-6 col-sm-12 hiddensm" style={{ backgroundImage: 'url(assets/images/seguimiento_profesional.png)', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '300px' }}></div>
			            <div className="col-md-6 col-sm-12 verticalalign px-5">
		                    <h3 className="margin-bottom-small titulo">SEGUIMIENTO PROFESIONAL</h3>
		                    <p className="text-justify" style={{ paddingTop: '15px' }}>Si es la primera vez que vas a consultar con él o la especialista, menciónalo al iniciar la consulta, es importante que sigas las indicaciones que te brinden en caso de requerir una consulta física en su oficina.</p>
			            </div>
		            </div>
			    </div>

			    <div className="container-fluid">
			        <div className="row grey-background conocenos py-5">
			            <div className="col-md-6 col-sm-12 verticalalign px-5">
		                    <h3 className="margin-bottom-small titulo">UNETE A LA RED</h3>
		                    <p className="text-justify" style={{ paddingTop: '15px' }}>Medpacom es un medio de comunicación formal y profesional para estar en contacto con tus especialistas de confianza, en caso de que tu doctor o doctora no cuenten con la aplicación, invítalo a bajarla en la sección de profesionales, pídele el nombre de usuario que registró y comienza una conversación donde se profesionaliza la consulta a distancia con una herramienta que te dará la tranquilidad de recibir las indicaciones y recetas de tus especialistas.</p>
			            </div>
			        	<div className="col-md-6 col-sm-12 hiddensm" style={{ backgroundImage: 'url(assets/images/unete_red.png)', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '300px' }}></div>
		            </div>
			    </div>

			    <div className="container-fluid">
			        <div className="row conocenos">
			        	<div className="col-md-6 col-sm-12 hiddensm" style={{ backgroundImage: 'url(assets/images/celulares.png)', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '450px' }}></div>
			            <div className="col-md-6 col-sm-12 verticalalign px-5">
		                    <h3 className="margin-bottom-small titulo">DISPONIBLE EN TIENDAS</h3>
		                    <p className="text-justify" style={{ paddingTop: '15px' }}>Estamos disponibles en las principales tiendas de aplicaciones, tanto en App Store como Play Store. Puedes descargarlas dando click en los siguientes botones:</p>
		                    <div className="row mt-5">
		                		<div className="col-md-6 col-sm-12 text-center">
		                			<a href="https://apps.apple.com/mx/app/id1521874012" target="_blank">
		                				<img src="assets/images/appstore.png" alt="" style={{ height: '50px' }} />
	                				</a>
	                			</div>
	                			<div className="col-md-6 col-sm-12 text-center">
	                				<a href="https://play.google.com/store/apps/details?id=com.solgeek.medpacompaciente" target="_blank">
		                				<img src="assets/images/playstore.png" alt="" style={{ height: '50px' }} />
	                				</a>
	                			</div>
                			</div>
			            </div>
		            </div>
			    </div>
		    </div>
	    </Fragment>
    )
}

export default HomePaciente;