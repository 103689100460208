import axios from 'axios';
import { LOAD_FACTURAS, PAGAR_FACTURAS } from './types';
import { setAlert } from './alert';

// LOAD USER
export const loadFacturas = () => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/web_medico_cobros`);
        dispatch({
            type: LOAD_FACTURAS,
            payload: res.data
        })
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// Subir la factura
export const subirFactura = formData => async dispatch => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_medico_cobros/facturar`, formData, config);
        console.log(res);
        dispatch({
            type: PAGAR_FACTURAS,
            payload: res.data
        })
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}
