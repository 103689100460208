
// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const NEW_MEDICO = 'NEW_MEDICO';
export const RECOVER_MEDICO = 'RECOVER_MEDICO';
export const RECOVER_PACIENTE = 'RECOVER_PACIENTE';

// Facturas
export const LOAD_FACTURAS = 'LOAD_FACTURAS';
export const PAGAR_FACTURAS = 'PAGAR_FACTURAS';

// Perfil
export const LOAD_PERFIL = 'LOAD_PERFIL';
export const EDITAR_PERFIL = 'EDITAR_PERFIL';

// Medicos
export const LOAD_MEDICOS = 'LOAD_MEDICOS';
export const AGREGAR_MEDICO = 'AGREGAR_MEDICO';
export const CREAR_MEDICO = 'CREAR_MEDICO';


