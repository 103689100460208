import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { login } from '../../Redux/actions/auth'

const styles = {
    rowStyle: {
        height: '80vh',
    },
    bgImg: {
        height: '40vw',
        opacity: 0.05,
        position: 'absolute',
        left: '-220px',
        top: '0px',
    },
    btnIniciar: {
        background: '#42B4D9',
        borderColor: '#42B4D9'
    }    
}

const Login = ({ }) => {

    let location = useLocation();

    const dispatch = useDispatch();
    
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });

    const { isAuthenticated, type } = useSelector(state => state.auth);

    // Redireccionamos si está autenticado
    if (isAuthenticated && type === 1) return <Redirect push to="/dashboard_medico" />;
    if (isAuthenticated && type === 2) return <Redirect push to="/dashboard_paciente" />;
    if (isAuthenticated && type === 3) return <Redirect push to="/dashboard_colaborador" />;

    const { email, password } = loginData;

    const onChange = (e) => {
        setLoginData({
            ...loginData, [e.target.name]: e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        if (location.pathname === '/login_medico') {
            dispatch(login({ type: 1, email, password }));
        }
        if (location.pathname === '/login_paciente') {
            dispatch(login({ type: 2, email, password }));
        }
        if (location.pathname === '/login_colaborador') {
            dispatch(login({ type: 3, email, password }));
        }
    }

    return (
        <div className="row d-flex align-items-center" style={styles.rowStyle}>
            <img src="assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-6 offset-md-3">
                <div className="card bg-light ">
                    <div className="card-body">
                        <div className="text-center mb-5">
                            <img src="assets/images/logo_azul.png" className="d-inline-block my-4" alt="logo" height="45" />
                            <h4 className="card-title">¡Bienvenido a Medpacom!</h4>
                            {
                                location.pathname === '/login_paciente' ?
                                <p className="text-muted">Estás iniciando sesión como paciente
                                    <span>
                                        <a href="/login_medico">, ir a iniciar sesion como médico</a>
                                    </span>
                                </p>   
                                :
                                    location.pathname === '/login_medico' ?
                                    <p className="text-muted">Estás iniciando sesión como médico
                                        <span>
                                            <a href="/login_paciente">, ir a iniciar sesion como paciente</a>
                                        </span>
                                    </p>
                                    :
                                    <p className="text-muted">Estás iniciando sesión como colaborador, si aún no tienes una cuenta 
                                        <span>
                                            <a href="/registro_colaborador"> da click aquí</a>
                                        </span>
                                    </p>   
                            }
                        </div>
                        <form onSubmit={submitForm} method="POST">
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Correo o nombre de usuario"
                                    name="email"
                                    value={email}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Contraseña"
                                    name="password"
                                    value={password}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group text-right pt-3">
                                <button type="submit" className="btn btn-primary" style={styles.btnIniciar}>Iniciar sesión</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>)
}

export default Login;