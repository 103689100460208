import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAILED, USER_LOADED, AUTH_ERROR, RECOVER_MEDICO, RECOVER_PACIENTE, LOGOUT } from './types';
import setAuthToken from '../../Utils/setAuthToken';
import { setAlert } from './alert';


// LOAD USER
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        let res;
        try {
            res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/web_paciente_auth`);
            dispatch({
                type: USER_LOADED,
                payload: {
                    user: res.data.paciente,
                    type: 2
                }
            });
        } catch (error) {
            try {
                res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/web_medico_auth`);
                dispatch({
                    type: USER_LOADED,
                    payload: {
                        user: res.data.medico,
                        type: 1
                    }
                });
            } catch (error) {
                res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/web_colaborador_auth`);
                dispatch({
                    type: USER_LOADED,
                    payload: {
                        user: res.data.colaborador,
                        type: 3
                    }
                });
            }
        }

    } catch (error) {
        dispatch({
            type: AUTH_ERROR
        })
    }

}

// LOGIN USER
export const login = ({ type, email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ email, password });
    try {
        let url;
        if (type === 1) {
            url = `${process.env.REACT_APP_API_ROUTE}/web_medico_auth`;
            const res = await axios.post(url, body, config);
            console.log(res.data);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    type,
                    token: res.data.token,
                    user: res.data.medico
                }
            });
        }

        if (type === 2) {
            url = `${process.env.REACT_APP_API_ROUTE}/web_paciente_auth`;
            const res = await axios.post(url, body, config);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    type,
                    token: res.data.token,
                    user: res.data.paciente
                }
            });
        }

        if (type === 3) {
            url = `${process.env.REACT_APP_API_ROUTE}/web_colaborador_auth`;
            const res = await axios.post(url, body, config);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    type,
                    token: res.data.token,
                    user: res.data.colaborador
                }
            });
        }

    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: LOGIN_FAILED
        })
    }
}

// REGISTER USER
export const register = ({ nombre, apellido, celular, email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ nombre, apellido, celular, email, password });
    try {
        let url = `${process.env.REACT_APP_API_ROUTE}/web_colaborador_auth/registrar`;
        const res = await axios.post(url, body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                type: 3,
                token: res.data.token,
                user: res.data.colaborador
            }
        });
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: LOGIN_FAILED
        })
    }
}

// UNSUSCRIBE COLABORADOR
export const unsuscribeColaborador = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_colaborador_auth/desuscribir`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// UNSUSCRIBE PACIENTE
export const unsuscribePaciente = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_paciente_auth/desuscribir`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// UNSUSCRIBE MEDICO
export const unsuscribeMedico = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_medico_auth/desuscribir`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// RECOVER PACIENTE
export const recoverPaciente = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        email
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_paciente_auth/confirmar_correo`, body, config);

        dispatch({
            type: RECOVER_PACIENTE,
            payload: res.data.codigo
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// DELETE PACIENTE
export const deletePaciente = (data) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(data);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_paciente_auth/eliminar`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// RECOVER MEDICO
export const recoverMedico = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        email
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_medico_auth/confirmar_correo`, body, config);

        dispatch({
            type: RECOVER_MEDICO,
            payload: res.data.codigo
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// DELETE MEDICO
export const deleteMedico = (data) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(data);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_medico_auth/eliminar`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// Logout
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}