import { LOGIN_SUCCESS, LOGIN_FAILED, USER_LOADED, AUTH_ERROR, LOGOUT, NEW_MEDICO, RECOVER_MEDICO, RECOVER_PACIENTE } from '../actions/types';

import setAuthToken from '../../Utils/setAuthToken';

const initialState = {
    type: undefined,
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    user: {},
    codigo: null
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            setAuthToken(localStorage.token);
            return {
                ...state, 
                ...payload,
                isAuthenticated: true, 
                loading: false,
            }
        case LOGIN_FAILED:
        case AUTH_ERROR:
        case LOGOUT: 
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false
            }
        case USER_LOADED:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false, 
            }
        case NEW_MEDICO:
            return {
                ...state,
                user: {
                    ...state.user,
                    numero_medicos: payload
                }
            }
        case RECOVER_MEDICO:
            return {
                ...state,
                codigo: payload,
            };
        case RECOVER_PACIENTE:
            return {
                ...state,
                codigo: payload,
            };
        default:
            return state;
    }

}