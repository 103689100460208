import React from 'react'

const Footer = () => {
    return (
        <footer id="bottom_footer" className="fixed" >
            <div className="row">
                <div className="col-md-4">
                    <div className="logo">
                        <img src="/assets/images/logo_blanco.png" className="normal" alt="logo" />
                        <img src="/assets/images/logo_blanco.png" className="retina" alt="logo" />
                    </div>
                </div>
                <div className="col-md-2">
                    <h6 className="heading white margin-bottom-extrasmall">Ir a</h6>
                    <ul className="sitemap">
                        <li><a href="/Home">Inicio</a></li>
                        <li><a href="/Terminos">Terminos y condiciones</a></li>
                        <li><a href="/Privacidad">Aviso de privacidad</a></li>
                        <li><a href="/login_colaborador">Colaboradores</a></li>
                    </ul>
                </div>
                <div className="col-md-2">
                    <h6 className="heading white margin-bottom-extrasmall">Social</h6>
                    <ul className="info">
                        <li><a target="_blank" href="https://www.facebook.com/medpacom/" rel="noopener noreferrer">Facebook</a></li>
                        <li><a target="_blank" href="https://twitter.com/medpacom" rel="noopener noreferrer">Twitter</a></li>
                        <li><a target="_blank" href="https://www.instagram.com/medpacom.app/" rel="noopener noreferrer">Instagram</a></li>
                    </ul>
                </div>
                <div className="col-md-4">
                    <h6 className="heading white margin-bottom-extrasmall">Contáctanos</h6>
                    <ul className="info">
                        <li>Celular: <span className="white"><a href="https://api.whatsapp.com/send?phone=528180161861" className="white">818 016 18 61</a></span></li>
                        <li>Oficina: <span className="white">(81) 21 87 99 16</span></li>
                        <li>Correo: <span className="white"><a haref="mailto:contacto@medpacom.com" className="white">contacto@medpacom.com</a></span></li>                        
                    </ul>
                </div>
            </div>
            <div className="copy col-md-12 padding-leftright-null">
                <p className="p_footer">MEDPACOM { new Date().getFullYear() } TODOS LOS DERECHOS RESERVADOS</p>
            </div>
        </footer>
    )
}

export default Footer;