import React, { useEffect, useState, createRef } from 'react';

import { loadPerfil, editarPerfil } from '../../Redux/actions/perfil_colaborador'
import { useDispatch, useSelector } from 'react-redux';

const Perfil = () => {

    const perfil = useSelector(state => state.perfil);

    useEffect(() => {
        load();
    }, []);

    const dispatch = useDispatch();
    const load = () => {
        dispatch(loadPerfil());
    }

    const [perfilData, setPerfilData ] = useState({
        id: null,
        nombre: '',
        apellido: '',
        celular: '',
        titular: '',
        num_cuenta: '',
        clabe: '',
        rfc: '',
        banco: null
    });

    useEffect(() => {
        setPerfilData({
            ...perfilData,
            id: perfil.perfil?._id,
            nombre: perfil.perfil?.nombre,
            apellido: perfil.perfil?.apellido,
            celular: perfil.perfil?.celular,
            titular: perfil.perfil?.cuenta.titular,
            num_cuenta: perfil.perfil?.cuenta.num_cuenta,
            clabe: perfil.perfil?.cuenta.clabe,
            rfc: perfil.perfil?.cuenta.rfc,
            banco: perfil.perfil?.cuenta.banco
        })
    }, [perfil]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Hacemos las validaciones necesarias
        try {
            dispatch(editarPerfil(perfilData));
        } catch (error) {
            return false;
        }
    }

    const onChange = (e) => {
        setPerfilData({
            ...perfilData, [e.target.name]: e.target.value
        })
    }

    const handleBanco = () => {
        let banco = document.getElementById('select_bancos');

        if (banco.value != 0) {
            setPerfilData({
                ...perfilData,
                banco: banco.value
            });
        }else{
            setPerfilData({
                ...perfilData,
                banco: null
            });
        }
    }

    return (
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Información personal</h3>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Nombre</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e) } value={perfilData.nombre || ''}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Apellido</label> <br></br>
                        <input type="text" className="form-control" placeholder="Apellido" name="apellido" onChange={e => onChange(e) } value={perfilData.apellido || ''}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Teléfono</label> <br></br>
                        <input type="text" className="form-control" placeholder="Teléfono" name="celular" onChange={e => onChange(e) } value={perfilData.celular || ''}></input>
                    </div>
                </div>

                <h3 className="mb-3 mt-5">Información bancaria</h3>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Titular</label> <br></br>
                        <input type="text" className="form-control" placeholder="Titular" name="titular" onChange={e => onChange(e) } value={perfilData.titular || ''}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">RFC</label> <br></br>
                        <input type="text" className="form-control" placeholder="RFC" name="rfc" onChange={e => onChange(e) } value={perfilData.rfc || ''}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">CLABE</label> <br></br>
                        <input type="text" className="form-control" placeholder="CLABE" name="clabe" onChange={e => onChange(e) } value={perfilData.clabe || ''}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">No. de cuenta</label> <br></br>
                        <input type="text" className="form-control" placeholder="No. de cuenta" name="num_cuenta" onChange={e => onChange(e) } value={perfilData.num_cuenta || ''}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Banco</label> <br></br>
                        <select id="select_bancos" className="form-control" onChange={ handleBanco } value={ perfilData.banco || '' }>
                            <option value={0}>Elige una opción</option>
                            {
                                perfil.bancos?.length > 0 ?
                                    perfil.bancos?.map(banco => {
                                        return(
                                            <option key={banco._id} value={ banco._id }>
                                                { banco.nombre }
                                            </option>
                                        )
                                    })
                                : ''
                            }
                        </select>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary float-right" onClick={handleSubmit}>Guardar</button>
            </form>
        </div>
    )
}

export default Perfil;