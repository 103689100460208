import React, { useEffect } from "react";
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

// Components
import PrivateRoute from './Components/Routing/PrivateRoute';
import NotFoundPage from './Components/Routing/NotFoundPage';
import Loader from './Components/Layout/Loader';
import HeaderSSMedico from './Components/Layout/HeaderSSMedico';
import HeaderSSPaciente from './Components/Layout/HeaderSSPaciente';
import Header from './Components/Layout/Header';
import HeaderColaborador from './Components/Layout/HeaderColaborador';
import Footer from './Components/Layout/Footer';
import Alert from './Components/Layout/Alert';

// Views
import About from './Views/About';
import AboutPaciente from './Views/AboutPaciente';
import HomeMedico from './Views/HomeMedico';
import HomePaciente from './Views/HomePaciente';
import Home from './Views/Home';
import TutorialPaciente from './Views/TutorialPaciente';
import TutorialMedico from './Views/TutorialMedico';
import Terms from './Views/Terms';
import Privacy from './Views/Privacy';
import Login from './Views/Login';
import Register from './Views/Register';
import DashboardMedico from "./Views/DashboardMedico";
import DashboardPaciente from "./Views/DashboardPaciente";
import DashboardColaborador from "./Views/DashboardColaborador";
import MedicosColaborador from "./Views/MedicosColaborador";
import PerfilColaborador from "./Views/PerfilColaborador";
import DesuscripcionColaborador from "./Views/DesuscripcionColaborador";
import DesuscripcionPaciente from "./Views/DesuscripcionPaciente";
import DesuscripcionMedico from "./Views/DesuscripcionMedico";
import EliminacionMedico from "./Views/EliminacionMedico";
import EliminacionPaciente from "./Views/EliminacionPaciente";

// Redux
import store from './store';
import setAuthToken from './Utils/setAuthToken';
import { loadUser } from './Redux/actions/auth'

const App = ({ history }) => {

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    useEffect(() => {
        store.dispatch(loadUser());
    }, [])

    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Loader />
                        <div id="main-wrap">
                            <Header />
                            <div id="page-content" className="header-static footer-fixed">
                                <Home />
                            </div>
                            <Footer />
                        </div>
                    </Route>                    
                    <Route exact path="/medico">
                        <Loader />
                        <div id="main-wrap">
                            <HeaderSSMedico />
                            <div id="page-content" className="header-static footer-fixed">
                                <HomeMedico />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route exact path="/paciente">
                        <Loader />
                        <div id="main-wrap">
                            <HeaderSSPaciente />
                            <div id="page-content" className="header-static footer-fixed">
                                <HomePaciente />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route exact path="/login_medico">
                        <Alert></Alert>
                        <Loader />
                        <div id="main-wrap">
                            <div id="page-content" className="header-static">
                                <Login history={history} />
                            </div>
                        </div>
                    </Route>
                    <Route exact path="/login_paciente">
                        <Alert></Alert>
                        <Loader />
                        <div id="main-wrap">
                            <div id="page-content" className="header-static">
                                <Login history={history} />
                            </div>
                        </div>
                    </Route>
                    <Route exact path="/login_colaborador">
                        <Alert></Alert>
                        <Loader />
                        <div id="main-wrap">
                            <div id="page-content" className="header-static">
                                <Login history={history} />
                            </div>
                        </div>
                    </Route>
                    <Route exact path="/registro_colaborador">
                        <Alert></Alert>
                        <Loader />
                        <div id="main-wrap">
                            <div id="page-content" className="header-static">
                                <Register history={history} />
                            </div>
                        </div>
                    </Route>
                    <Route exact path="/about">
                        <Loader />
                        <div id="main-wrap">
                            <HeaderSSMedico />
                            <div id="page-content" className="header-static footer-fixed">
                                <About />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route exact path="/about_paciente">
                        <Loader />
                        <div id="main-wrap">
                            <HeaderSSPaciente />
                            <div id="page-content" className="header-static footer-fixed">
                                <AboutPaciente />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route exact path="/tutorial_paciente">
                        <Loader />
                        <div id="main-wrap">
                            <HeaderSSPaciente />
                            <div id="page-content" className="header-static footer-fixed">
                                <TutorialPaciente />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route exact path="/tutorial_medico">
                        <Loader />
                        <div id="main-wrap">
                            <HeaderSSMedico />
                            <div id="page-content" className="header-static footer-fixed">
                                <TutorialMedico />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route exact path="/terminos">
                        <Loader />
                        <div id="main-wrap">
                            <Header />
                            <div id="page-content" className="header-static footer-fixed">
                                <Terms />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route exact path="/terms">
                        <Terms />
                    </Route>
                    <Route exact path="/privacidad">
                        <Loader />
                        <div id="main-wrap">
                            <Header />
                            <div id="page-content" className="header-static footer-fixed">
                                <Privacy />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route exact path="/privacy">
                        <Privacy />
                    </Route>
                    <Route exact path="/desuscripcion_medico/:id">
                        <Alert></Alert>
                        <Loader />
                        <div id="main-wrap">
                            <div id="page-content" className="header-static">
                                <DesuscripcionMedico history={history} />
                            </div>
                        </div>
                    </Route>
                    <Route exact path="/desuscripcion_paciente/:id">
                        <Alert></Alert>
                        <Loader />
                        <div id="main-wrap">
                            <div id="page-content" className="header-static">
                                <DesuscripcionPaciente history={history} />
                            </div>
                        </div>
                    </Route>
                    <Route exact path="/desuscripcion_colaborador/:id">
                        <Alert></Alert>
                        <Loader />
                        <div id="main-wrap">
                            <div id="page-content" className="header-static">
                                <DesuscripcionColaborador history={history} />
                            </div>
                        </div>
                    </Route>
                    <Route exact path="/eliminacion_medico">
                        <Alert></Alert>
                        <Loader />
                        <div id="main-wrap">
                            <div id="page-content" className="header-static">
                                <EliminacionMedico history={history} />
                            </div>
                        </div>
                    </Route>
                    <Route exact path="/eliminacion_paciente">
                        <Alert></Alert>
                        <Loader />
                        <div id="main-wrap">
                            <div id="page-content" className="header-static">
                                <EliminacionPaciente history={history} />
                            </div>
                        </div>
                    </Route>
                    <PrivateRoute exact path="/dashboard_medico">
                        <Alert></Alert>
                        <DashboardMedico />
                    </PrivateRoute>
                    <PrivateRoute exact path="/dashboard_paciente">
                        <Alert></Alert>
                        <DashboardPaciente />
                    </PrivateRoute>
                    <PrivateRoute exact path="/dashboard_colaborador">
                        <Alert></Alert>
                        <DashboardColaborador />
                    </PrivateRoute>
                    <PrivateRoute exact path="/perfil_colaborador">
                        <Alert></Alert>
                        <HeaderColaborador />
                        <PerfilColaborador />
                    </PrivateRoute>
                    <PrivateRoute exact path="/medicos_colaborador">
                        <Alert></Alert>
                        <HeaderColaborador />
                        <MedicosColaborador />
                    </PrivateRoute>
                    <Route path="/">
                        <Loader />
                        <div id="main-wrap">
                            <Header />
                            <div id="page-content" className="header-static footer-fixed">
                                <Home />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                </Switch>
            </Router>
        </Provider >
    );
}

export default App;