import axios from 'axios';
import { LOAD_MEDICOS, AGREGAR_MEDICO, CREAR_MEDICO, NEW_MEDICO } from './types';
import { setAlert } from './alert';

// Cargar medicos
export const loadMedicos = () => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/web_colaborador_medicos`);
        dispatch({
            type: LOAD_MEDICOS,
            payload: res.data.red_medica
        })
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// Agregar medico a la red
export const agregarMedico = (medico) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(medico);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_colaborador_medicos/agregar`, body, config);
        console.log(res);
        dispatch({
            type: AGREGAR_MEDICO,
            payload: res.data.medico
        });
        dispatch({
            type: NEW_MEDICO,
            payload: res.data.total_medicos
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// Crear nuevo medico y agregarlo a la red
export const crearMedico = (medico) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(medico);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/web_colaborador_medicos/crear`, body, config);
        console.log(res);
        dispatch({
            type: AGREGAR_MEDICO,
            payload: res.data.medico
        });
        dispatch({
            type: NEW_MEDICO,
            payload: res.data.total_medicos
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}
